export const EscortsTypePageConstant = {
    escortsTypes: 'Reception_Management_Escorts_Type.escortstypes',
    escortsType: 'Reception_Management_Escorts_Type.escortstype',
    name: 'Reception_Management_Escorts_Type.name',
    title: 'Reception_Management_Escorts_Type.title',
    icon: 'Reception_Management_Escorts_Type.icon',
    image: 'Reception_Management_Escorts_Type.image',
    description: 'Reception_Management_Escorts_Type.description',
    title: 'Reception_Management_Escorts_Type.title',
    parentName: 'Reception_Management_Escorts_Type.parentname',
    code: 'Reception_Management_Escorts_Type.code',
    shape: 'Reception_Management_Escorts_Type.shape',
    isActive: 'Reception_Management_Escorts_Type.isactive',
    treeList: 'Reception_Management_Escorts_Type.treelist',
    info: 'Reception_Management_Escorts_Type.info',
    uploadIcon: 'Reception_Management_Escorts_Type.uploadicon',
    edit: 'Reception_Management_Escorts_Type.edit',
    delete: 'Reception_Management_Escorts_Type.delete',
    treeViewer: 'Reception_Management_Escorts_Type.treeviewer',
    treeViewerAdd: 'Reception_Management_Escorts_Type.treevieweradd',
    treeViewerDelete: 'Reception_Management_Escorts_Type.treeviewerdelete',
    treeViewerEdit: 'Reception_Management_Escorts_Type.treevieweredit',
    treeViewerActions: 'Reception_Management_Escorts_Type.treevieweractions',
    actions: 'Reception_Management_Escorts_Type.actions',
    search: 'Reception_Management_Escorts_Type.search',
    reset: 'Reception_Management_Escorts_Type.reset',
    actions: 'Reception_Management_Escorts_Type.actions',
    selectLanguage: 'Reception_Management_Escorts_Type.selectlanguage',
    escortsTypeTypeParent: 'Reception_Management_Escorts_Type.escortstypetypeparent',
    history: 'Reception_Management_Escorts_Type.history',
    createdDate: 'Reception_Management_Escorts_Type.createddate',
    updatedDate: 'Reception_Management_Escorts_Type.updateddate'
};
