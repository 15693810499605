export const MedicationsPageConstant = {
    medications: 'Medical_Management_Medications.medications',
    medication: 'Medical_Management_Medications.medication',
    name: 'Medical_Management_Medications.name',
    icon: 'Medical_Management_Medications.icon',
    image: 'Medical_Management_Medications.image',
    description: 'Medical_Management_Medications.description',
    drugCode: 'Medical_Management_Medications.drugcode',
    countries: 'Medical_Management_Medications.countries',
    cities: 'Medical_Management_Medications.cities',
    type: 'Medical_Management_Medications.type',
    similarMedicine: 'Medical_Management_Medications.similarmedicine',
    subtituteMedicine: 'Medical_Management_Medications.subtitutemedicine',
    drugCompositions: 'Medical_Management_Medications.drugcompositions',
    inventory: 'Medical_Management_Medications.inventory',
    company: 'Medical_Management_Medications.company',
    advertisalImage: 'Medical_Management_Medications.advertisalimage',
    productionDate: 'Medical_Management_Medications.productiondate',
    expiryDate: 'Medical_Management_Medications.expirydate',
    search: 'Medical_Management_Medications.search',
    reset: 'Medical_Management_Medications.reset',
    actions: 'Medical_Management_Medications.actions',
    edit: 'Medical_Management_Medications.edit',
    delete: 'Medical_Management_Medications.delete',
    createdDate: 'Medical_Management_Medications.createddate',
    updatedDate: 'Medical_Management_Medications.updateddate'
};
