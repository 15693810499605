export const MedicineTypePageConstant = {
    medicationsTypes: 'Medical_Management_Medicine_Type.medicationstypes',
    medicineType: 'Medical_Management_Medicine_Type.medicinetype',
    name: 'Medical_Management_Medicine_Type.name',
    icon: 'Medical_Management_Medicine_Type.icon',
    uploadIcon: 'Medical_Management_Medicine_Type.uploadicon',
    info: 'Medical_Management_Medicine_Type.info',
    isActive: 'Medical_Management_Medicine_Type.isactive',
    edit: 'Medical_Management_Medicine_Type.edit',
    actions: 'Medical_Management_Medicine_Type.actions',
    delete: 'Medical_Management_Medicine_Type.delete',
    createdDate: 'Medical_Management_Medicine_Type.createddate',
    updatedDate: 'Medical_Management_Medicine_Type.updateddate'
};
