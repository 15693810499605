export const AcademyPageConstant = {
    academics: 'Settings_Profile_Academic.academics',
    academy: 'Settings_Profile_Academic.academy',
    name: 'Settings_Profile_Academic.name',
    type: 'Settings_Profile_Academic.type',
    years: 'Settings_Profile_Academic.years',
    academicParent: 'Settings_Profile_Academic.academicparent',
    code: 'Settings_Profile_Academic.code',
    edit: 'Settings_Profile_Academic.edit',
    delete: 'Settings_Profile_Academic.delete',
    actions: 'Settings_Profile_Academic.actions',
    createdDate: 'Settings_Profile_Academic.createddate',
    updatedDate: 'Settings_Profile_Academic.updateddate'
};
