import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

// hooks
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useMatchDownMd from 'hooks/useMatchDownMd';
import { useTranslation } from 'react-i18next';
import useFetchData from 'hooks/query/useFetchData';

// Components
import { successLogOut } from 'features/Auth/authSlice';
import { changeLanguage } from 'features/Static/staticSlice';

// project imports
import AuthWrapper1 from 'views/authentication/AuthWrapper1';

// MUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';

// UI components
import AnimatedElements from 'ui-component/AnimatedElements';

// Constants
import { DEFAULT_LANGUAGES } from 'env/server';

// helpers
import { getActiveValue } from 'helpers';
import { useTheme } from '@mui/styles';

// ==============================|| MINIMAL LAYOUT ||============================== //
export const CyperDigitalTechText = () => {
    const matchDownMd = useMatchDownMd();

    return (
        <AnimatedElements startWith={4}>
            <Typography variant={matchDownMd ? 'h2' : 'h1'} color="secondary" mb={1}>
                <AnimatedElements
                    timeout={200}
                    boxSx={{
                        display: 'inline'
                    }}
                >
                    Cyber Digital Technology
                </AnimatedElements>
            </Typography>
            <Typography variant={matchDownMd ? 'h3' : 'h2'} color="#fff">
                <AnimatedElements
                    timeout={200}
                    boxSx={{
                        display: 'inline'
                    }}
                >
                    Digital And Tech Solutions
                </AnimatedElements>
            </Typography>
        </AnimatedElements>
    );
};

export const TranslationButton = () => {
    const { data } = useFetchData('defaultLanguages', DEFAULT_LANGUAGES.GET_ACTIVE_LANGUAGES);
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const matchDownMd = useMatchDownMd();

    // data
    const open = Boolean(anchorEl);
    const languages = data?.data?.data?.data ?? [];
    // functions
    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleChangeLang = (value) => {
        i18n.changeLanguage(value);
        localStorage.setItem('activeLanguage', value);
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton
                sx={{
                    color: (theme) => (matchDownMd ? '#FFF' : theme.palette.primary.main)
                }}
                onClick={handleOpen}
            >
                <TranslateRoundedIcon />
            </IconButton>
            <Menu
                width="200px"
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {languages.map(({ key, name }) => (
                    <MenuItem onClick={() => handleChangeLang(key)} key={key}>
                        {getActiveValue(name)}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
};
const MinimalLayout = () => {
    const matchDownMd = useMatchDownMd();
    const dispatch = useDispatch();
    const thems = useTheme();
    useEffect(() => {
        dispatch(successLogOut());
    }, [dispatch]);

    return matchDownMd ? (
        <AuthWrapper1>
            <Box
                sx={(theme) => ({
                    backgroundColor: (theme) => theme.palette.primary.main,
                    padding: '15px',
                    borderRadius: '30px',
                    m: 1,
                    [theme.breakpoints.up('md')]: {
                        display: 'none'
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                })}
            >
                <Box>
                    <CyperDigitalTechText />
                </Box>
                <TranslationButton />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Outlet />
            </Box>
        </AuthWrapper1>
    ) : (
        <AuthWrapper1>
            <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
                <Grid
                    item
                    sx={{ m: { xs: 1, sm: 3 }, paddingTop: 1 }}
                    order={{
                        xs: 0,
                        md: 1
                    }}
                >
                    <CyperDigitalTechText />
                </Grid>
                <Grid
                    item
                    sx={{
                        m: { xs: 1, sm: 3 },
                        mb: 0,
                        background: '#FFF',
                        padding: 3,
                        maxWidth: { xs: 400, lg: 475 },
                        '& > *': {
                            flexGrow: 1,
                            flexBasis: '50%'
                        },
                        boxShadow: 3,
                        borderRadius: '30px',
                        position: 'relative'
                    }}
                >
                    <Outlet />
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default MinimalLayout;
