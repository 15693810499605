export const MedicalWarehousesPageConstant = {
    medicalWarehouses: 'Medical_Management_Pharmacy_Warehouses.medicalwarehouses',
    medicalWarehouse: 'Medical_Management_Pharmacy_Warehouses.medicalwarehouse',
    name: 'Medical_Management_Pharmacy_Warehouses.name',
    icon: 'Medical_Management_Pharmacy_Warehouses.icon',
    image: 'Medical_Management_Pharmacy_Warehouses.image',
    description: 'Medical_Management_Pharmacy_Warehouses.description',
    companies: 'Medical_Management_Pharmacy_Warehouses.companies',
    nationalContactInfos: 'Medical_Management_Pharmacy_Warehouses.nationalcontactinfos',
    city: 'Medical_Management_Pharmacy_Warehouses.city',
    phoneNumbers: 'Medical_Management_Pharmacy_Warehouses.phonenumbers',
    search: 'Medical_Management_Pharmacy_Warehouses.search',
    reset: 'Medical_Management_Pharmacy_Warehouses.reset',
    edit: 'Medical_Management_Pharmacy_Warehouses.edit',
    actions: 'Medical_Management_Pharmacy_Warehouses.actions',
    delete: 'Medical_Management_Pharmacy_Warehouses.delete',
    createdDate: 'Medical_Management_Pharmacy_Warehouses.createddate',
    updatedDate: 'Medical_Management_Pharmacy_Warehouses.updateddate'
};
