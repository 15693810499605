export const PatientProfessionPageConstant = {
    profession: 'Settings_Patient_Profession.profession',
    patient: 'Settings_Patient_Profession.patient',
    mainProfession: 'Settings_Patient_Profession.mainprofessions',
    pendingProfession: 'Settings_Patient_Profession.pendingprofessions',
    name: 'Settings_Patient_Profession.name',
    member: 'Settings_Patient_Profession.member',
    code: 'Settings_Patient_Profession.code',
    icon: 'Settings_Patient_Profession.icon',
    count: 'Settings_Patient_Profession.count',
    description: 'Settings_Patient_Profession.description',
    confirm: 'Settings_Patient_Profession.confirm',
    replace: 'Settings_Patient_Profession.replace',
    replaceProfession: 'Settings_Patient_Profession.replaceprofession',
    save: 'Settings_Patient_Profession.save',
    cancel: 'Settings_Patient_Profession.cancel',
    uniqueCode: 'Settings_Patient_Profession.uniquecode',
    oldProfession: 'Settings_Patient_Profession.oldprofession',
    actions: 'Settings_Patient_Profession.actions',
    edit: 'Settings_Patient_Profession.edit',
    delete: 'Settings_Patient_Profession.delete',
    createdDate: 'Settings_Patient_Profession.createddate',
    updatedDate: 'Settings_Patient_Profession.updateddate'
};
