export const MedicalReferralsPageConstant = {
    medicalReferrals: 'Reception_Management_Medical_Referrals.medicalreferrals',
    medicalReferral: 'Reception_Management_Medical_Referrals.medicalreferral',
    mainReferrals: 'Reception_Management_Medical_Referrals.mainreferrals',
    pendingReferrals: 'Reception_Management_Medical_Referrals.pendingreferrals',
    name: 'Reception_Management_Medical_Referrals.name',
    icon: 'Reception_Management_Medical_Referrals.icon',
    image: 'Reception_Management_Medical_Referrals.image',
    description: 'Reception_Management_Medical_Referrals.description',
    title: 'Reception_Management_Medical_Referrals.title',
    parentName: 'Reception_Management_Medical_Referrals.parentname',
    code: 'Reception_Management_Medical_Referrals.code',
    shape: 'Reception_Management_Medical_Referrals.shape',
    isActive: 'Reception_Management_Medical_Referrals.isactive',
    treeList: 'Reception_Management_Medical_Referrals.treelist',
    info: 'Reception_Management_Medical_Referrals.info',
    uploadIcon: 'Reception_Management_Medical_Referrals.uploadicon',
    edit: 'Reception_Management_Medical_Referrals.edit',
    delete: 'Reception_Management_Medical_Referrals.delete',
    treeViewer: 'Reception_Management_Medical_Referrals.treeviewer',
    treeViewerAdd: 'Reception_Management_Medical_Referrals.treevieweradd',
    treeViewerDelete: 'Reception_Management_Medical_Referrals.treeviewerdelete',
    treeViewerEdit: 'Reception_Management_Medical_Referrals.treevieweredit',
    treeViewerActions: 'Reception_Management_Medical_Referrals.treevieweractions',
    actions: 'Reception_Management_Medical_Referrals.actions',
    search: 'Reception_Management_Medical_Referrals.search',
    reset: 'Reception_Management_Medical_Referrals.reset',
    actions: 'Reception_Management_Medical_Referrals.actions',
    selectLanguage: 'Reception_Management_Medical_Referrals.selectlanguage',
    medicalReferralsParent: 'Reception_Management_Medical_Referrals.medicalreferralsparent',
    history: 'Reception_Management_Medical_Referrals.history',
    confirm: 'Reception_Management_Medical_Referrals.confirm',
    replace: 'Reception_Management_Medical_Referrals.replace',
    replaceReferral: 'Reception_Management_Medical_Referrals.replacereferral',
    save: 'Reception_Management_Medical_Referrals.save',
    cancel: 'Reception_Management_Medical_Referrals.cancel',
    createdDate: 'Reception_Management_Medical_Referrals.createddate',
    updatedDate: 'Reception_Management_Medical_Referrals.updateddate'
};
