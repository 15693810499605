export const CountryPageConstant = {
    countries: 'Settings_Address_Countries.countries',
    country: 'Settings_Address_Countries.country',
    name: 'Settings_Address_Countries.name',
    code: 'Settings_Address_Countries.code',
    edit: 'Settings_Address_Countries.edit',
    actions: 'Settings_Address_Countries.actions',
    delete: 'Settings_Address_Countries.delete',
    createdDate: 'Settings_Address_Countries.createddate',
    updatedDate: 'Settings_Address_Countries.updateddate'
};
