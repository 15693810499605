import React, { useState } from 'react';
import PropTypes from 'prop-types';

// MUI Components
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styled from '@mui/styles/styled';
import IconButton from '@mui/material/IconButton';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

// hooks
import useSystemLanguages from 'hooks/systemLanguages/useSystemLanguages';
import useSidebar from 'hooks/useSidebar';
import { AnimatePresence, motion } from 'framer-motion';
import useMatchDownMd from 'hooks/useMatchDownMd';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //
const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: theme?.direction === 'rtl' ? 'rotate(180deg)' : 'none'
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export const FadeTransition = ({ open, ...rest }) => {
    return (
        <AnimatePresence>
            {open && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ transitionDelay: '0', opacity: 0 }}
                    style={{ transition: '.3s all ease-out', width: '100%', ...rest?.style }}
                    {...rest}
                />
            )}
        </AnimatePresence>
    );
};

const NavGroup = ({ item, icon, handleClose, isCollapse }) => {
    const getTranslation = useSystemLanguages();
    const [expanded, setExpanded] = useState(false);
    const { open, handleToggle } = useSidebar();
    const matchDownMd = useMatchDownMd();
    // menu list Collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'group':
                return <NavGroup {...{ item: menu, key: menu.id, icon: menu.icon, handleClose, isCollapse }} />;
            case 'Collapse':
                return <NavCollapse handleClose={handleClose} key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem handleClose={handleClose} key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };
    return (
        <React.Fragment>
            <FadeTransition open={!open && !matchDownMd} style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                <IconButton onClick={handleToggle} color="primary">
                    {icon && icon}
                </IconButton>
            </FadeTransition>
            <FadeTransition open={open}>
                {isCollapse ? (
                    <Accordion
                        expanded={expanded}
                        onChange={handleAccordionChange}
                        sx={{
                            borderRadius: 0,
                            '& *': {
                                fontWeight: 'bold !important'
                            },
                            backgroundColor: (theme) => theme.palette.primary.main
                        }}
                    >
                        <AccordionSummary
                            sx={{
                                '& div': { margin: '0 !important' }
                            }}
                            aria-controls="nav-group-content"
                            id="nav-group-header"
                        >
                            <ListItem sx={{ paddingLeft: 0 }}>
                                <ListItemIcon>{icon && icon}</ListItemIcon>
                                <ListItemText primary={<Typography variant="body1">{getTranslation(item.title)}</Typography>} />
                            </ListItem>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: 1 }}>
                            <List subheader={null}>{items}</List>
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <List subheader={null}>{items}</List>
                )}
            </FadeTransition>
        </React.Fragment>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
