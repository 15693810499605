export const MaritalStatusPageConstant = {
    maritalStatuses: 'Settings_Profile_Marital_Status.maritalstatuses',
    maritalStatus: 'Settings_Profile_Marital_Status.maritalstatus',
    name: 'Settings_Profile_Marital_Status.name',
    code: 'Settings_Profile_Marital_Status.code',
    edit: 'Settings_Profile_Marital_Status.edit',
    actions: 'Settings_Profile_Marital_Status.actions',
    delete: 'Settings_Profile_Marital_Status.delete',
    createdDate: 'Settings_Profile_Marital_Status.createddate',
    updatedDate: 'Settings_Profile_Marital_Status.updateddate'
};
