import { CommonPageConstant } from 'constant/Translations/common';
import i18next from 'i18next';
import moment from 'moment';

export const activationCells = (props) => {
    return [
        {
            accessorKey: 'activationFrom',
            header: props.activationFrom,
            size: 190,
            Cell: ({ row }) =>
                row?.original?.activationTo ? moment(row?.original?.activationFrom).format('YYYY/MM/DD ,HH:mm:ss') : 'All Time',
            accessorFn: (row) => new Date(row.activationFrom), //convert to Date for sorting and filtering
            sortingFn: 'datetime'
        },
        {
            accessorKey: 'ActivationTo',
            header: props.activationTo,
            size: 190,
            Cell: ({ row }) =>
                row?.original?.activationTo ? moment(row?.original?.activationTo).format('YYYY/MM/DD ,HH:mm:ss') : 'All Time',
            accessorFn: (row) => new Date(row.activationTo), //convert to Date for sorting and filtering,
            sortingFn: 'datetime'
        }
    ];
};

export const activationDetailsInfo = (row) => [
    {
        title: `${i18next.t(CommonPageConstant.activationFrom)}: `,
        value: row?.original?.activationTo ? moment(row?.original?.activationFrom).format('YYYY/MM/DD ,HH:mm:ss') : 'All Time'
    },
    {
        title: `${i18next.t(CommonPageConstant.activationTo)}: `,
        value: row?.original?.activationTo ? moment(row?.original?.activationTo).format('YYYY/MM/DD ,HH:mm:ss') : 'All Time'
    }
];
