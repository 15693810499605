export const StatePageConstant = {
    states: 'Settings_Address_States.states',
    state: 'Settings_Address_States.state',
    name: 'Settings_Address_States.name',
    country: 'Settings_Address_States.country',
    code: 'Settings_Address_States.code',
    actions: 'Settings_Address_States.actions',
    edit: 'Settings_Address_States.edit',
    delete: 'Settings_Address_States.delete',
    createdDate: 'Settings_Address_States.createddate',
    updatedDate: 'Settings_Address_States.updateddate'
};
