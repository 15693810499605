export const DrugCompositionPageConstant = {
    drugComposition: 'Medical_Management_Drug_Composition.drugcomposition',
    name: 'Medical_Management_Drug_Composition.name',
    icon: 'Medical_Management_Drug_Composition.icon',
    image: 'Medical_Management_Drug_Composition.image',
    description: 'Medical_Management_Drug_Composition.description',
    edit: 'Medical_Management_Drug_Composition.edit',
    actions: 'Medical_Management_Drug_Composition.actions',
    delete: 'Medical_Management_Drug_Composition.delete',
    createdDate: 'Medical_Management_Drug_Composition.createddate',
    updatedDate: 'Medical_Management_Drug_Composition.updateddate'
};
