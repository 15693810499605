export const FileTemplatesPageConstant = {
    fileTemplates: 'File_Templates_File_Templates.filetemplates',
    fileTemplate: 'File_Templates_File_Templates.filetemplate',
    personalFile: 'File_Templates_File_Templates.personalfile',
    contactFile: 'File_Templates_File_Templates.contactfile',
    medicalFile: 'File_Templates_File_Templates.medicalfile',
    scientificFile: 'File_Templates_File_Templates.scientificfile',
    professionalFile: 'File_Templates_File_Templates.professionalfile',
    generalCareerFile: 'File_Templates_File_Templates.generalcareerfile',
    patientFile: 'File_Templates_File_Templates.patientfile',
    pateintsFiles: 'File_Templates_File_Templates.pateintsfiles',
    careerFile: 'File_Templates_File_Templates.careerfile',
    hrFile: 'File_Templates_File_Templates.hrfile',
    blankFile: 'File_Templates_File_Templates.blankfile',
    chooseTemplate: 'File_Templates_File_Templates.choosetemplate',
    memberFile: 'File_Templates_File_Templates.memberfile',
    name: 'File_Templates_File_Templates.name',
    image: 'File_Templates_File_Templates.image',
    isDefault: 'File_Templates_File_Templates.isdefault',
    memberCategories: 'File_Templates_File_Templates.membercategories',
    open: 'File_Templates_File_Templates.open',
    display: 'File_Templates_File_Templates.display',
    duplicate: 'File_Templates_File_Templates.duplicate',
    edit: 'File_Templates_File_Templates.edit',
    delete: 'File_Templates_File_Templates.delete',
    actions: 'File_Templates_File_Templates.actions',
    title: 'File_Templates_File_Templates.title',
    code: 'File_Templates_File_Templates.code',
    isActive: 'File_Templates_File_Templates.isactive',
    viewMemberCategories: 'File_Templates_File_Templates.viewmembercategories',
    jobTitlesCount: 'File_Templates_File_Templates.jobtitlescount',
    membersCount: 'File_Templates_File_Templates.memberscount',
    info: 'File_Templates_File_Templates.info',
    menuText: 'File_Templates_File_Templates.menutext',
    menuTextTooltip: 'File_Templates_File_Templates.menutexttooltip',
    menuTable: 'File_Templates_File_Templates.menutable',
    menuSpreadSheet: 'File_Templates_File_Templates.menuspreadsheet',
    menuSpreadSheetTooltip: 'File_Templates_File_Templates.menuspreadsheettooltip',
    menuDataGrid: 'File_Templates_File_Templates.menudatagrid',
    menuDataGridTooltip: 'File_Templates_File_Templates.menudatagridtooltip',
    menuChart: 'File_Templates_File_Templates.menuchart',
    menuChartTooltip: 'File_Templates_File_Templates.menucharttooltip',
    menuTextField: 'File_Templates_File_Templates.menutextfield',
    menuTextFieldTooltip: 'File_Templates_File_Templates.menutextfieldtooltip',
    menuTimeline: 'File_Templates_File_Templates.menutimeline',
    menuTree: 'File_Templates_File_Templates.menutree',
    menuSubmitButton: 'File_Templates_File_Templates.menusubmitbutton',
    menuResetButton: 'File_Templates_File_Templates.menuresetbutton',
    menuLayout: 'File_Templates_File_Templates.menulayout',
    menuToggleSwipe: 'File_Templates_File_Templates.menutoggleswipe',
    menuBasicComponents: 'File_Templates_File_Templates.menubasiccomponents',
    menuLayoutComponents: 'File_Templates_File_Templates.menulayoutcomponents',
    menuPageComponents: 'File_Templates_File_Templates.menupagecomponents',
    menuDataGridCols: 'File_Templates_File_Templates.menudatagridcols',
    menuGrid: 'File_Templates_File_Templates.menugrid',
    menuGridTooltip: 'File_Templates_File_Templates.menugridtooltip',
    menuExpand: 'File_Templates_File_Templates.menuexpand',
    menuExpandTooltip: 'File_Templates_File_Templates.menuexpandtooltip',
    menuFlexLayout: 'File_Templates_File_Templates.menuflexlayout',
    menuFlexLayoutTooltip: 'File_Templates_File_Templates.menuflexlayouttooltip',
    menuTabs: 'File_Templates_File_Templates.menutabs',
    menuTabsTooltip: 'File_Templates_File_Templates.menutabstooltip',
    menuView: 'File_Templates_File_Templates.menuview',
    menuViewHeader: 'File_Templates_File_Templates.menuviewheader',
    menuViewFooter: 'File_Templates_File_Templates.menuviewfooter',
    menuViewWaterMark: 'File_Templates_File_Templates.menuviewwatermark',
    menuViewPageSettings: 'File_Templates_File_Templates.menuviewpagesettings',
    menuFileUpload: 'File_Templates_File_Templates.menufileupload',
    menuFileDownload: 'File_Templates_File_Templates.menufiledownload',
    menuFileOpen: 'File_Templates_File_Templates.menufileopen',
    menuFileSave: 'File_Templates_File_Templates.menufilesave',
    menuFileSaveAs: 'File_Templates_File_Templates.menufilesaveas',
    menuFileExportToPdf: 'File_Templates_File_Templates.menufileexporttopdf',
    menuFileExportToWord: 'File_Templates_File_Templates.menufileexporttoword',
    menuFilePublishTemplate: 'File_Templates_File_Templates.menufilepublishtemplate',
    menuFileUpdateTemplate: 'File_Templates_File_Templates.menufileupdatetemplate',
    menuFilePublishTemplateCancel: 'File_Templates_File_Templates.menufilepublishtemplatecancel',
    menuFilePublishTemplatePublish: 'File_Templates_File_Templates.menufilepublishtemplatepublish',
    menuFileDuplicateTemplate: 'File_Templates_File_Templates.menufileduplicatetemplate',
    menuFileDuplicateTemplateCancel: 'File_Templates_File_Templates.menufileduplicatetemplatecancel',
    menuFileDuplicateTemplateDuplicate: 'File_Templates_File_Templates.menufileduplicatetemplateduplicate',
    previewMode: 'File_Templates_File_Templates.previewmode',
    printMode: 'File_Templates_File_Templates.printmode',
    editMode: 'File_Templates_File_Templates.editmode',
    textTextSettings: 'File_Templates_File_Templates.texttextsettings',
    textLabel: 'File_Templates_File_Templates.textlabel',
    chartType: 'File_Templates_File_Templates.charttype',
    chartTable: 'File_Templates_File_Templates.charttable',
    chartDataSeries: 'File_Templates_File_Templates.chartdataseries',
    chartRender: 'File_Templates_File_Templates.chartrender',
    chartChartSettings: 'File_Templates_File_Templates.chartchartsettings',
    textFieldTextFieldSettings: 'File_Templates_File_Templates.textfieldtextfieldsettings',
    textFieldLivePreview: 'File_Templates_File_Templates.textfieldlivepreview',
    textFieldPrintMode: 'File_Templates_File_Templates.textfieldprintmode',
    textFieldEnableLabel: 'File_Templates_File_Templates.textfieldenablelabel',
    textFieldDefaultLabel: 'File_Templates_File_Templates.textfielddefaultlabel',
    textFieldFormat: 'File_Templates_File_Templates.textfieldformat',
    textFieldValueStyle: 'File_Templates_File_Templates.textfieldvaluestyle',
    textFieldKeyType: 'File_Templates_File_Templates.textfieldkeytype',
    textFieldControl: 'File_Templates_File_Templates.textfieldcontrol',
    textFieldName: 'File_Templates_File_Templates.textfieldname',
    textFieldLabel: 'File_Templates_File_Templates.textfieldlabel',
    textFieldHintText: 'File_Templates_File_Templates.textfieldhinttext',
    textFieldOnlyPrinted: 'File_Templates_File_Templates.textfieldonlyprinted',
    textFieldVariant: 'File_Templates_File_Templates.textfieldvariant',
    textFieldValidation: 'File_Templates_File_Templates.textfieldvalidation',
    textFieldIsHidden: 'File_Templates_File_Templates.textfieldishidden',
    textFieldIsRequired: 'File_Templates_File_Templates.textfieldisrequired',
    textFieldTime: 'File_Templates_File_Templates.textfieldtime',
    textFieldDate: 'File_Templates_File_Templates.textfielddate',
    textFieldDateTime: 'File_Templates_File_Templates.textfielddatetime',
    textFieldLength: 'File_Templates_File_Templates.textfieldlength',
    textFieldMin: 'File_Templates_File_Templates.textfieldmin',
    textFieldMax: 'File_Templates_File_Templates.textfieldmax',
    timelineTimelineSettings: 'File_Templates_File_Templates.timelinetimelinesettings',
    timelineLayout: 'File_Templates_File_Templates.timelinelayout',
    timelineLineColor: 'File_Templates_File_Templates.timelinelinecolor',
    timelineTitle: 'File_Templates_File_Templates.timelinetitle',
    timelineSubtitle: 'File_Templates_File_Templates.timelinesubtitle',
    timelineText: 'File_Templates_File_Templates.timelinetext',
    timelineDate: 'File_Templates_File_Templates.timelinedate',
    timelineBackgroundColor: 'File_Templates_File_Templates.timelinebackgroundcolor',
    timelineIconColor: 'File_Templates_File_Templates.timelineiconcolor',
    timelineDateStyle: 'File_Templates_File_Templates.timelinedatestyle',
    timelineItems: 'File_Templates_File_Templates.timelineitems',
    timelineEnableExternalDataSource: 'File_Templates_File_Templates.timelineenableexternaldatasource',
    timelineGetEndpoint: 'File_Templates_File_Templates.timelinegetendpoint',
    timelineKey: 'File_Templates_File_Templates.timelinekey',
    timelineLabel: 'File_Templates_File_Templates.timelinelabel',
    timelineItem: 'File_Templates_File_Templates.timelineitem',
    timelineTextStyle: 'File_Templates_File_Templates.timelinetextstyle',
    treeTreeSettings: 'File_Templates_File_Templates.treetreesettings',
    treePathFunc: 'File_Templates_File_Templates.treepathfunc',
    treeOrientation: 'File_Templates_File_Templates.treeorientation',
    treeNodeSizeX: 'File_Templates_File_Templates.treenodesizex',
    treeNodeSizeY: 'File_Templates_File_Templates.treenodesizey',
    treeSiblings: 'File_Templates_File_Templates.treesiblings',
    treeNonSiblings: 'File_Templates_File_Templates.treenonsiblings',
    treeEnableExternalDataSource: 'File_Templates_File_Templates.treeenableexternaldatasource',
    treeGetEndpoint: 'File_Templates_File_Templates.treegetendpoint',
    treeWidth: 'File_Templates_File_Templates.treewidth',
    treeBackgroundColor: 'File_Templates_File_Templates.treebackgroundcolor',
    treeKey: 'File_Templates_File_Templates.treekey',
    treeStyle: 'File_Templates_File_Templates.treestyle',
    treeRenderedValues: 'File_Templates_File_Templates.treerenderedvalues',
    treeDataField: 'File_Templates_File_Templates.treedatafield',
    treeData: 'File_Templates_File_Templates.treedata',
    treeName: 'File_Templates_File_Templates.treename',
    treeHasSecondaryLabel: 'File_Templates_File_Templates.treehassecondarylabel',
    treeSecondaryLabel: 'File_Templates_File_Templates.treesecondarylabel',
    treeShape: 'File_Templates_File_Templates.treeshape',
    treeChildren: 'File_Templates_File_Templates.treechildren',
    treeChild: 'File_Templates_File_Templates.treechild',
    submitButtonSettings: 'File_Templates_File_Templates.submitbuttonsettings',
    submitButtonText: 'File_Templates_File_Templates.submitbuttontext',
    submitButtonVariant: 'File_Templates_File_Templates.submitbuttonvariant',
    submitButtonColor: 'File_Templates_File_Templates.submitbuttoncolor',
    submitButtonFullWidth: 'File_Templates_File_Templates.submitbuttonfullwidth',
    gridSettings: 'File_Templates_File_Templates.gridsettings',
    gridXs: 'File_Templates_File_Templates.gridxs',
    gridSm: 'File_Templates_File_Templates.gridsm',
    gridMd: 'File_Templates_File_Templates.gridmd',
    gridLg: 'File_Templates_File_Templates.gridlg',
    gridXl: 'File_Templates_File_Templates.gridxl',
    gridSpacing: 'File_Templates_File_Templates.gridspacing',
    expandSettings: 'File_Templates_File_Templates.expandsettings',
    expandTitle: 'File_Templates_File_Templates.expandtitle',
    expandWithLeftBorder: 'File_Templates_File_Templates.expandwithleftborder',
    expandXs: 'File_Templates_File_Templates.expandxs',
    expandSm: 'File_Templates_File_Templates.expandsm',
    expandMd: 'File_Templates_File_Templates.expandmd',
    expandLg: 'File_Templates_File_Templates.expandlg',
    expandXl: 'File_Templates_File_Templates.expandxl',
    expandSpacing: 'File_Templates_File_Templates.expandspacing',
    flexLayoutSettings: 'File_Templates_File_Templates.flexlayoutsettings',
    flexLayoutAlignItems: 'File_Templates_File_Templates.flexlayoutalignitems',
    flexLayoutJustifyContent: 'File_Templates_File_Templates.flexlayoutjustifycontent',
    tabsSettings: 'File_Templates_File_Templates.tabssettings',
    tabsTabHeight: 'File_Templates_File_Templates.tabstabheight',
    tabs: 'File_Templates_File_Templates.tabs',
    tabsLabel: 'File_Templates_File_Templates.tabslabel',
    tabsTreatAsSeperateForm: 'File_Templates_File_Templates.tabstreatasseperateform',
    tabsFile: 'File_Templates_File_Templates.tabsfile',
    tabsTab: 'File_Templates_File_Templates.tabstab',
    footerSettings: 'File_Templates_File_Templates.footersettings',
    headerSettings: 'File_Templates_File_Templates.headersettings',
    footer: 'File_Templates_File_Templates.footer',
    header: 'File_Templates_File_Templates.header',
    headerAndFooterText: 'File_Templates_File_Templates.headerandfootertext',
    headerAndFooterEnablePageNumbring: 'File_Templates_File_Templates.headerandfooterenablepagenumbring',
    headerAndFooterPageNumbringY: 'File_Templates_File_Templates.headerandfooterpagenumbringy',
    headerAndFooterPageNumbringX: 'File_Templates_File_Templates.headerandfooterpagenumbringx',
    headerAndFooterPageNumbringStyle: 'File_Templates_File_Templates.headerandfooterpagenumbringstyle',
    pageSettings: 'File_Templates_File_Templates.pagesettings',
    pageType: 'File_Templates_File_Templates.pagetype',
    pagePageSize: 'File_Templates_File_Templates.pagepagesize',
    watermarkSettings: 'File_Templates_File_Templates.watermarksettings',
    watermarkWarningMessage: 'File_Templates_File_Templates.watermarkwarningmessage',
    watermarkUploadImage: 'File_Templates_File_Templates.watermarkuploadimage',
    watermarkWidth: 'File_Templates_File_Templates.watermarkwidth',
    watermarkHeight: 'File_Templates_File_Templates.watermarkheight',
    watermarkOpacity: 'File_Templates_File_Templates.watermarkopacity',
    stylesStylesSettings: 'File_Templates_File_Templates.stylesstylessettings',
    styles: 'File_Templates_File_Templates.styles',
    stylesStyle: 'File_Templates_File_Templates.stylesstyle',
    stylesName: 'File_Templates_File_Templates.stylesname',
    stylesSettings: 'File_Templates_File_Templates.stylessettings',
    maringMaringSettings: 'File_Templates_File_Templates.maringmaringsettings',
    maringMarginLeft: 'File_Templates_File_Templates.maringmarginleft',
    maringMarginRight: 'File_Templates_File_Templates.maringmarginright',
    maringMarginBottom: 'File_Templates_File_Templates.maringmarginbottom',
    maringMarginTop: 'File_Templates_File_Templates.maringmargintop',
    animateAnimateSettings: 'File_Templates_File_Templates.animateanimatesettings',
    animateEnable: 'File_Templates_File_Templates.animateenable',
    animateType: 'File_Templates_File_Templates.animatetype',
    animateTimeout: 'File_Templates_File_Templates.animatetimeout',
    animateDirection: 'File_Templates_File_Templates.animatedirection',
    conditionsConditionsSettings: 'File_Templates_File_Templates.conditionsconditionssettings',
    conditionsAction: 'File_Templates_File_Templates.conditionsaction',
    conditionsRelation: 'File_Templates_File_Templates.conditionsrelation',
    conditionsIsGroup: 'File_Templates_File_Templates.conditionsisgroup',
    conditionsTextField: 'File_Templates_File_Templates.conditionstextfield',
    conditionsConditionType: 'File_Templates_File_Templates.conditionsconditiontype',
    conditionsEffectValue: 'File_Templates_File_Templates.conditionseffectvalue',
    conditions: 'File_Templates_File_Templates.conditions',
    conditionsCondition: 'File_Templates_File_Templates.conditionscondition',
    apiOptionsApiOptionsSettings: 'File_Templates_File_Templates.apioptionsapioptionssettings',
    apiOptionsAddApi: 'File_Templates_File_Templates.apioptionsaddapi',
    apiOptionsUpdateApi: 'File_Templates_File_Templates.apioptionsupdateapi',
    apiOptionsGetApi: 'File_Templates_File_Templates.apioptionsgetapi',
    apiOptionsWhichKeysToUse: 'File_Templates_File_Templates.apioptionswhichkeystouse',
    history: 'File_Templates_File_Templates.history',
    historyHistoryId: 'File_Templates_File_Templates.historyhistoryid',
    historyInitializeTheApplication: 'File_Templates_File_Templates.historyinitializetheapplication',
    historyInitialization: 'File_Templates_File_Templates.historyinitialization',
    historyAssignLayoutToPage: 'File_Templates_File_Templates.historyassignlayouttopage',
    historyAssignBasicToLayout: 'File_Templates_File_Templates.historyassignbasictolayout',
    historyInsertBasic: 'File_Templates_File_Templates.historyinsertbasic',
    historyInsertBeforeBasic: 'File_Templates_File_Templates.historyinsertbeforebasic',
    historyInsertAfterBasic: 'File_Templates_File_Templates.historyinsertafterbasic',
    historyInsertLayout: 'File_Templates_File_Templates.historyinsertlayout',
    historyInsertBeforeLayout: 'File_Templates_File_Templates.historyinsertbeforelayout',
    historyInsertAfterLayout: 'File_Templates_File_Templates.historyinsertafterlayout',
    historyInsertPage: 'File_Templates_File_Templates.historyinsertpage',
    historyEditBasic: 'File_Templates_File_Templates.historyeditbasic',
    historyEditLayout: 'File_Templates_File_Templates.historyeditlayout',
    historyEditStyles: 'File_Templates_File_Templates.historyeditstyles',
    historyEditApiOptions: 'File_Templates_File_Templates.historyeditapioptions',
    historyEditHeader: 'File_Templates_File_Templates.historyeditheader',
    historyEditFooter: 'File_Templates_File_Templates.historyeditfooter',
    historyEditPageSettings: 'File_Templates_File_Templates.historyeditpagesettings',
    historyEditWatermark: 'File_Templates_File_Templates.historyeditwatermark',
    historyDeleteBasic: 'File_Templates_File_Templates.historydeletebasic',
    historyDeleteLayout: 'File_Templates_File_Templates.historydeletelayout',
    historyDeletePage: 'File_Templates_File_Templates.historydeletepage',
    historyDuplicateBasic: 'File_Templates_File_Templates.historyduplicatebasic',
    historyDuplicateLayout: 'File_Templates_File_Templates.historyduplicatelayout',
    historySwipeBasic: 'File_Templates_File_Templates.historyswipebasic',
    historySwipeLayout: 'File_Templates_File_Templates.historyswipelayout',
    historyPageSwipe: 'File_Templates_File_Templates.historypageswipe',
    historyDescriptionAddedText: 'File_Templates_File_Templates.historydescriptionaddedtext',
    historyDescriptionAddedTextField: 'File_Templates_File_Templates.historydescriptionaddedtextfield',
    historyDescriptionAddedTable: 'File_Templates_File_Templates.historydescriptionaddedtable',
    historyDescriptionAddedSpreadsheet: 'File_Templates_File_Templates.historydescriptionaddedspreadsheet',
    historyDescriptionAddedDatagrid: 'File_Templates_File_Templates.historydescriptionaddeddatagrid',
    historyDescriptionAddedTree: 'File_Templates_File_Templates.historydescriptionaddedtree',
    historyDescriptionAddedChart: 'File_Templates_File_Templates.historydescriptionaddedchart',
    historyDescriptionAddedTimeline: 'File_Templates_File_Templates.historydescriptionaddedtimeline',
    historyDescriptionAddedSubmitButton: 'File_Templates_File_Templates.historydescriptionaddedsubmitbutton',
    historyDescriptionEditText: 'File_Templates_File_Templates.historydescriptionedittext',
    historyDescriptionEditTextField: 'File_Templates_File_Templates.historydescriptionedittextfield',
    historyDescriptionEditTable: 'File_Templates_File_Templates.historydescriptionedittable',
    historyDescriptionEditSpreadsheet: 'File_Templates_File_Templates.historydescriptioneditspreadsheet',
    historyDescriptionEditDatagrid: 'File_Templates_File_Templates.historydescriptioneditdatagrid',
    historyDescriptionEditTree: 'File_Templates_File_Templates.historydescriptionedittree',
    historyDescriptionEditChart: 'File_Templates_File_Templates.historydescriptioneditchart',
    historyDescriptionEditTimeline: 'File_Templates_File_Templates.historydescriptionedittimeline',
    historyDescriptionEditSubmitButton: 'File_Templates_File_Templates.historydescriptioneditsubmitbutton',
    historyDescriptionDeletedText: 'File_Templates_File_Templates.historydescriptiondeletedtext',
    historyDescriptionDeletedTextField: 'File_Templates_File_Templates.historydescriptiondeletedtextfield',
    historyDescriptionDeletedTable: 'File_Templates_File_Templates.historydescriptiondeletedtable',
    historyDescriptionDeletedSpreadsheet: 'File_Templates_File_Templates.historydescriptiondeletedspreadsheet',
    historyDescriptionDeletedDatagrid: 'File_Templates_File_Templates.historydescriptiondeleteddatagrid',
    historyDescriptionDeletedTree: 'File_Templates_File_Templates.historydescriptiondeletedtree',
    historyDescriptionDeletedChart: 'File_Templates_File_Templates.historydescriptiondeletedchart',
    historyDescriptionDeletedTimeline: 'File_Templates_File_Templates.historydescriptiondeletedtimeline',
    historyDescriptionDeletedSubmitButton: 'File_Templates_File_Templates.historydescriptiondeletedsubmitbutton',
    historyDescriptionDuplicatedText: 'File_Templates_File_Templates.historydescriptionduplicatedtext',
    historyDescriptionDuplicatedTextField: 'File_Templates_File_Templates.historydescriptionduplicatedtextfield',
    historyDescriptionDuplicatedTable: 'File_Templates_File_Templates.historydescriptionduplicatedtable',
    historyDescriptionDuplicatedSpreadsheet: 'File_Templates_File_Templates.historydescriptionduplicatedspreadsheet',
    historyDescriptionDuplicatedDatagrid: 'File_Templates_File_Templates.historydescriptionduplicateddatagrid',
    historyDescriptionDuplicatedTree: 'File_Templates_File_Templates.historydescriptionduplicatedtree',
    historyDescriptionDuplicatedChart: 'File_Templates_File_Templates.historydescriptionduplicatedchart',
    historyDescriptionDuplicatedTimeline: 'File_Templates_File_Templates.historydescriptionduplicatedtimeline',
    historyDescriptionDuplicatedSubmitButton: 'File_Templates_File_Templates.historydescriptionduplicatedsubmitbutton',

    historyDescriptionAddedGrid: 'File_Templates_File_Templates.historydescriptionaddedgrid',
    historyDescriptionAddedExpand: 'File_Templates_File_Templates.historydescriptionaddedexpand',
    historyDescriptionAddedFlexLayout: 'File_Templates_File_Templates.historydescriptionaddedflexlayout',
    historyDescriptionAddedTabs: 'File_Templates_File_Templates.historydescriptionaddedtabs',

    historyDescriptionEditGrid: 'File_Templates_File_Templates.historydescriptioneditgrid',
    historyDescriptionEditExpand: 'File_Templates_File_Templates.historydescriptioneditexpand',
    historyDescriptionEditFlexLayout: 'File_Templates_File_Templates.historydescriptioneditflexlayout',
    historyDescriptionEditTabs: 'File_Templates_File_Templates.historydescriptionedittabs',

    historyDescriptionDeletedGrid: 'File_Templates_File_Templates.historydescriptiondeletedgrid',
    historyDescriptionDeletedExpand: 'File_Templates_File_Templates.historydescriptiondeletedexpand',
    historyDescriptionDeletedFlexLayout: 'File_Templates_File_Templates.historydescriptiondeletedflexlayout',
    historyDescriptionDeletedTabs: 'File_Templates_File_Templates.historydescriptiondeletedtabs',
    historyDescriptionDuplicatedGrid: 'File_Templates_File_Templates.historydescriptionduplicatedgrid',
    historyDescriptionDuplicatedExpand: 'File_Templates_File_Templates.historydescriptionduplicatedexpand',
    historyDescriptionDuplicatedFlexLayout: 'File_Templates_File_Templates.historydescriptionduplicatedflexlayout',
    historyDescriptionDuplicatedTabs: 'File_Templates_File_Templates.historydescriptionduplicatedtabs',

    historyDescriptionAssignedGridToPage: 'File_Templates_File_Templates.historydescriptionassignedgridtopage',
    historyDescriptionAssignedExpandToPage: 'File_Templates_File_Templates.historydescriptionassignedexpandtopage',
    historyDescriptionAssignedFlexLayoutToPage: 'File_Templates_File_Templates.historydescriptionassignedflexlayouttopage',

    historyDescriptionAssignedTextToLayout: 'File_Templates_File_Templates.historydescriptionassignedtexttolayout',
    historyDescriptionAssignedTextFieldToLayout: 'File_Templates_File_Templates.historydescriptionassignedtextfieldtolayout',
    historyDescriptionAssignedTableToLayout: 'File_Templates_File_Templates.historydescriptionassignedtabletolayout',
    historyDescriptionAssignedSpreadsheetToLayout: 'File_Templates_File_Templates.historydescriptionassignedspreadsheettolayout',
    historyDescriptionAssignedDatagridToLayout: 'File_Templates_File_Templates.historydescriptionassigneddatagridtolayout',
    historyDescriptionAssignedTreeToLayout: 'File_Templates_File_Templates.historydescriptionassignedtreetolayout',
    historyDescriptionAssignedChartToLayout: 'File_Templates_File_Templates.historydescriptionassignedcharttolayout',
    historyDescriptionAssignedTimelineToLayout: 'File_Templates_File_Templates.historydescriptionassignedtimelinetolayout',
    historyDescriptionAssignedSubmitButtonToLayout: 'File_Templates_File_Templates.historydescriptionassignedsubmitbuttontolayout',

    historyDescriptionInsertBeforeBasic: 'File_Templates_File_Templates.historydescriptioninsertbeforebasic',
    historyDescriptionInsertAfterBasic: 'File_Templates_File_Templates.historydescriptioninsertafterbasic',
    historyDescriptionInsertBeforeLayout: 'File_Templates_File_Templates.historydescriptioninsertbeforelayout',
    historyDescriptionInsertAfterLayout: 'File_Templates_File_Templates.historydescriptioninsertafterlayout',
    historyDescriptionInsertPage: 'File_Templates_File_Templates.historydescriptioninsertpage',
    historyDescriptionEditStyles: 'File_Templates_File_Templates.historydescriptioneditstyles',
    historyDescriptionEditApiOptions: 'File_Templates_File_Templates.historydescriptioneditapioptions',
    historyDescriptionEditHeader: 'File_Templates_File_Templates.historydescriptioneditheader',
    historyDescriptionEditFooter: 'File_Templates_File_Templates.historydescriptioneditfooter',
    historyDescriptionEditPageSettings: 'File_Templates_File_Templates.historydescriptioneditpagesettings',
    historyDescriptionEditWatermark: 'File_Templates_File_Templates.historydescriptioneditwatermark',
    historyDescriptionDeletePage: 'File_Templates_File_Templates.historydescriptiondeletepage',
    historyDescriptionSwipeBasic: 'File_Templates_File_Templates.historydescriptionswipebasic',
    historyDescriptionSwipeLayout: 'File_Templates_File_Templates.historydescriptionswipelayout',
    historyDescriptionPageSwipe: 'File_Templates_File_Templates.historydescriptionpageswipe',

    historyDescriptionAddedColToDataGrid: 'File_Templates_File_Templates.historydescriptionaddedcoltodatagrid',
    historyDescriptionDeletedDataGridCol: 'File_Templates_File_Templates.historydescriptiondeleteddatagridcol',
    historyDescriptionDeletedRowInDataGrid: 'File_Templates_File_Templates.historydescriptiondeletedrowindatagrid',
    historyDescriptionSwipedBetween2DataGridCols: 'File_Templates_File_Templates.historydescriptionswipedbetween2datagridcols',
    historyDescriptionAddedColsToTable: 'File_Templates_File_Templates.historydescriptionaddedcolstotable',
    historyDescriptionAddedRowsToTable: 'File_Templates_File_Templates.historydescriptionaddedrowstotable',
    historyDescriptionDeletedMultipleColumnsInSpreadSheetTable:
        'File_Templates_File_Templates.historydescriptiondeletedmultiplecolumnsinspreadsheettable',
    historyDescriptionDeletedMultipleRowsInSpreadSheetTable:
        'File_Templates_File_Templates.historydescriptiondeletedmultiplerowsinspreadsheettable',
    historyDescriptionDeletedColumnInSpreadSheetTable: 'File_Templates_File_Templates.historydescriptiondeletedcolumninspreadsheettable',
    historyDescriptionDeletedRowInSpreadSheetTable: 'File_Templates_File_Templates.historydescriptiondeletedrowinspreadsheettable',
    historyDescriptionInsertedColumnInSpreadSheetTable: 'File_Templates_File_Templates.historydescriptioninsertedcolumninspreadsheettable',
    historyDescriptionInsertedRowInSpreadSheetTable: 'File_Templates_File_Templates.historydescriptioninsertedrowinspreadsheettable',

    fileTemplateNumberingPatternFirstNumber: 'File_Templates_File_Templates.filetemplatenumberingpatternfirstnumber',
    fileTemplateNumberingPatternNumberingType: 'File_Templates_File_Templates.filetemplatenumberingpatternnumberingtype',
    fileTemplateNumberingPatternNumberOfDigits: 'File_Templates_File_Templates.filetemplatenumberingpatternnumberofdigits',
    fileTemplateNumberingPatternAlphabetTypes: 'File_Templates_File_Templates.filetemplatenumberingpatternalphabettypes',
    fileTemplateNumberingPatternCharSequence: 'File_Templates_File_Templates.filetemplatenumberingpatterncharsequence',
    fileTemplateNumberingPatternNumberOfCharacters: 'File_Templates_File_Templates.filetemplatenumberingpatternnumberofcharacters',
    fileTemplateNumberingPatternNumberOfCapitalLetterCharacters:
        'File_Templates_File_Templates.filetemplatenumberingpatternnumberofcapitallettercharacters',
    fileTemplateNumberingPatternIsCharFirst: 'File_Templates_File_Templates.filetemplatenumberingpatternischarfirst',
    fileTemplateNumberingPatternIsCapitalFirst: 'File_Templates_File_Templates.filetemplatenumberingpatterniscapitalfirst',
    fileTemplateNumberingPatternHasEquivalentIncrease: 'File_Templates_File_Templates.filetemplatenumberingpatternhasequivalentincrease',
    fileTemplateNumberingPatternHasExpandChar: 'File_Templates_File_Templates.filetemplatenumberingpatternhasexpandchar',
    dataGridCol: 'File_Templates_File_Templates.datagridcol',
    dataGridColCellStyle: 'File_Templates_File_Templates.datagridcolcellstyle',
    dataGridColHeaderName: 'File_Templates_File_Templates.datagridcolheadername',
    dataGridColAccessorKey: 'File_Templates_File_Templates.datagridcolaccessorkey',
    dataGridColType: 'File_Templates_File_Templates.datagridcoltype',
    dataGridColSize: 'File_Templates_File_Templates.datagridcolsize',
    dataGridColConditions: 'File_Templates_File_Templates.datagridcolconditions',
    dataGridColActionValue: 'File_Templates_File_Templates.datagridcolactionvalue',
    dataGridColEffectValue: 'File_Templates_File_Templates.datagridcoleffectvalue',
    dataGridLinkWithOtherColumn: 'File_Templates_File_Templates.datagridlinkwithothercolumn',
    dataGridPleaseEnterHeaderName: 'File_Templates_File_Templates.datagridpleaseenterheadername',
    dataGridAlreadyExistsHeaderAndAccessorKeyShouldBeUnique:
        'File_Templates_File_Templates.datagridalreadyexistsheaderandaccessorkeyshouldbeunique',
    dataGridColIfCellIsValue: 'File_Templates_File_Templates.datagridcolifcellisvalue',
    dataGridColThenMakeThisCellIsStyle: 'File_Templates_File_Templates.datagridcolthenmakethiscellisstyle',
    dataGridColText: 'File_Templates_File_Templates.datagridcoltext',
    dataGridColCondition: 'File_Templates_File_Templates.datagridcolcondition',
    dataGridColClose: 'File_Templates_File_Templates.datagridcolclose',
    dataGridColEdit: 'File_Templates_File_Templates.datagridcoledit',
    dataGridColAdd: 'File_Templates_File_Templates.datagridcoladd',
    dataGridColArrayCols: 'File_Templates_File_Templates.datagridcolarraycols',
    dataGridColArrayCol: 'File_Templates_File_Templates.datagridcolarraycol',
    externalDataSourceWarning: 'File_Templates_File_Templates.externaldatasourcewarning',
    enable: 'File_Templates_File_Templates.enable',
    setExternalDataSource: 'File_Templates_File_Templates.setexternaldatasource',
    externalDataSource: 'File_Templates_File_Templates.externaldatasource',
    cancel: 'File_Templates_File_Templates.cancel',
    save: 'File_Templates_File_Templates.save',
    createdDate: 'File_Templates_File_Templates.createddate',
    updatedDate: 'File_Templates_File_Templates.updateddate'
};
