export const NicknamePageConstant = {
    nicknames: 'Settings_Profile_Nicknames.nicknames',
    nickname: 'Settings_Profile_Nicknames.nickname',
    name: 'Settings_Profile_Nicknames.name',
    code: 'Settings_Profile_Nicknames.code',
    edit: 'Settings_Profile_Nicknames.edit',
    actions: 'Settings_Profile_Nicknames.actions',
    delete: 'Settings_Profile_Nicknames.delete',
    createdDate: 'Settings_Profile_Nicknames.createddate',
    updatedDate: 'Settings_Profile_Nicknames.updateddate'
};
