export const DoctrinePageConstant = {
    doctrines: 'Settings_Profile_Doctrine.doctrines',
    doctrine: 'Settings_Profile_Doctrine.doctrine',
    name: 'Settings_Profile_Doctrine.name',
    code: 'Settings_Profile_Doctrine.code',
    religion: 'Settings_Profile_Doctrine.religion',
    edit: 'Settings_Profile_Doctrine.edit',
    actions: 'Settings_Profile_Doctrine.actions',
    delete: 'Settings_Profile_Doctrine.delete',
    createdDate: 'Settings_Profile_Doctrine.createddate',
    updatedDate: 'Settings_Profile_Doctrine.updateddate'
};
