import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authAxios } from 'config/axios';
import { AUTH } from 'env/server';
import { getAccount } from 'helpers';

const initialState = {
    loading: false,
    error: false,
    privileges: [],
    activeLanguage: 'en',
    languages: [],
    centerSections: [],
    centerCombinSections: [],
    token: null,
    account: {},
    ...getAccount()
};
export const logIn = createAsyncThunk('auth/logIn', async (values) => {
    const res = await authAxios.post(AUTH.LOGIN, values);
    return res.data;
});
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        successLogOut: (state, action) => {
            state.token = null;
            localStorage.setItem('account', null);
            localStorage.setItem('tokenContent', null);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logIn.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(logIn.fulfilled, (state, action) => {
            state.token = action.payload.data.accessToken;
            state.account = action.payload.data;
            state.loading = false;
            state.error = null;
            localStorage.setItem(
                'account',
                JSON.stringify({
                    token: action.payload.data.accessToken,
                    account: action.payload.data
                })
            );
        });
        builder.addCase(logIn.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export const { successLogOut } = authSlice.actions;

export default authSlice.reducer;
