export const MedicalVisitDescriptionPageConstant = {
    medicalVisitDescription: 'Reception_Management_Medical_Visit_Description.medicalvisitdescription',
    name: 'Reception_Management_Medical_Visit_Description.name',
    icon: 'Reception_Management_Medical_Visit_Description.icon',
    image: 'Reception_Management_Medical_Visit_Description.image',
    description: 'Reception_Management_Medical_Visit_Description.description',
    title: 'Reception_Management_Medical_Visit_Description.title',
    parentName: 'Reception_Management_Medical_Visit_Description.parentname',
    code: 'Reception_Management_Medical_Visit_Description.code',
    shape: 'Reception_Management_Medical_Visit_Description.shape',
    isActive: 'Reception_Management_Medical_Visit_Description.isactive',
    treeList: 'Reception_Management_Medical_Visit_Description.treelist',
    info: 'Reception_Management_Medical_Visit_Description.info',
    uploadIcon: 'Reception_Management_Medical_Visit_Description.uploadicon',
    edit: 'Reception_Management_Medical_Visit_Description.edit',
    delete: 'Reception_Management_Medical_Visit_Description.delete',
    treeViewer: 'Reception_Management_Medical_Visit_Description.treeviewer',
    treeViewerAdd: 'Reception_Management_Medical_Visit_Description.treevieweradd',
    treeViewerDelete: 'Reception_Management_Medical_Visit_Description.treeviewerdelete',
    treeViewerEdit: 'Reception_Management_Medical_Visit_Description.treevieweredit',
    treeViewerActions: 'Reception_Management_Medical_Visit_Description.treevieweractions',
    actions: 'Reception_Management_Medical_Visit_Description.actions',
    search: 'Reception_Management_Medical_Visit_Description.search',
    reset: 'Reception_Management_Medical_Visit_Description.reset',
    actions: 'Reception_Management_Medical_Visit_Description.actions',
    selectLanguage: 'Reception_Management_Medical_Visit_Description.selectlanguage',
    medicalVisitDescriptionParent: 'Reception_Management_Medical_Visit_Description.medicalvisitdescriptionparent',
    history: 'Reception_Management_Medical_Visit_Description.history',
    createdDate: 'Reception_Management_Medical_Visit_Description.createddate',
    updatedDate: 'Reception_Management_Medical_Visit_Description.updateddate'
};
