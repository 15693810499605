import React from 'react';
import { cellWithoutFilters } from './cellWithoutFilters';
import { get } from 'lodash';
import i18next from 'i18next';
import { CommonPageConstant } from 'constant/Translations/common';
export const iconCell = (props = {}) => {
    const { accessorKey = 'icon', header = i18next.t(CommonPageConstant.icon), ...rest } = props;
    return {
        size: 100,
        ...cellWithoutFilters,
        header: header,
        accessorKey: accessorKey,
        Cell: ({ row }) => (
            <img
                src={get(row.original, accessorKey)}
                style={{
                    width: '30px',
                    height: '30px',
                    display: 'block',
                    margin: 'auto'
                }}
            />
        ),
        ...rest
    };
};
