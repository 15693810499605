export const MemberCategoriesPageConstant = {
    memberCategories: 'Settings_Member_Categories.membercategories',
    memberCategory: 'Settings_Member_Categories.membercategory',
    title: 'Settings_Member_Categories.title',
    parentName: 'Settings_Member_Categories.parentname',
    code: 'Settings_Member_Categories.code',
    editFiles: 'Settings_Member_Categories.editfiles',
    personalFile: 'Settings_Member_Categories.personalfile',
    contactFile: 'Settings_Member_Categories.contactfile',
    scientificFile: 'Settings_Member_Categories.scientificfile',
    professionalFile: 'Settings_Member_Categories.professionalfile',
    generalCareerFile: 'Settings_Member_Categories.generalcareerfile',
    careerFile: 'Settings_Member_Categories.careerfile',
    hrFile: 'Settings_Member_Categories.hrfile',
    noFilesToEdit: 'Settings_Member_Categories.nofilestoedit',
    updateJobTitles: 'Settings_Member_Categories.updatejobtitles',
    members: 'Settings_Member_Categories.members',
    relatedJobTitles: 'Settings_Member_Categories.relatedjobtitles',
    treeList: 'Settings_Member_Categories.treelist',
    description: 'Settings_Member_Categories.description',
    shape: 'Settings_Member_Categories.shape',
    isActive: 'Settings_Member_Categories.isactive',
    mergedCategory: 'Settings_Member_Categories.mergedcategory',
    uploadIcon: 'Settings_Member_Categories.uploadicon',
    choosedMergeCategoriesButton: 'Settings_Member_Categories.choosedmergecategoriesbutton',
    mergedMemberCategories: 'Settings_Member_Categories.mergedmembercategories',
    cancel: 'Settings_Member_Categories.cancel',
    confrim: 'Settings_Member_Categories.confrim',
    info: 'Settings_Member_Categories.info',
    chooseCategoriesToMerge: 'Settings_Member_Categories.choosecategoriestomerge',
    template: 'Settings_Member_Categories.template',
    merge: 'Settings_Member_Categories.merge',
    mergeFiles: 'Settings_Member_Categories.mergefiles',
    files: 'Settings_Member_Categories.files',
    numbringPattern: 'Settings_Member_Categories.numbringpattern',
    viewJobTitles: 'Settings_Member_Categories.viewjobtitles',
    jobTitles: 'Settings_Member_Categories.jobtitles',
    edit: 'Settings_Member_Categories.edit',
    delete: 'Settings_Member_Categories.delete',
    relatedMemberCategories: 'Settings_Member_Categories.relatedmembercategories',
    jobTitlesCount: 'Settings_Member_Categories.jobtitlescount',
    membersCount: 'Settings_Member_Categories.memberscount',
    viewMemberCategories: 'Settings_Member_Categories.viewmembercategories',
    customizeFiles: 'Settings_Member_Categories.customizefiles',
    activeMembersCount: 'Settings_Member_Categories.activememberscount',
    unactiveMembersCount: 'Settings_Member_Categories.unactivememberscount',
    chooseJobTitles: 'Settings_Member_Categories.choosejobtitles',
    pickJobTitlesFromTree: 'Settings_Member_Categories.pickjobtitlesfromtree',
    pickJobTitlesFromTreeList: 'Settings_Member_Categories.pickjobtitlesfromtreelist',
    fileTemplates: 'Settings_Member_Categories.filetemplates',
    updateFileTemplates: 'Settings_Member_Categories.updatefiletemplates',
    update: 'Settings_Member_Categories.update',
    treeViewer: 'Settings_Member_Categories.treeviewer',
    treeViewerAdd: 'Settings_Member_Categories.treevieweradd',
    treeViewerDelete: 'Settings_Member_Categories.treeviewerdelete',
    treeViewerEdit: 'Settings_Member_Categories.treevieweredit',
    treeViewerActions: 'Settings_Member_Categories.treevieweractions',
    submit: 'Settings_Member_Categories.submit',
    search: 'Settings_Member_Categories.search',
    reset: 'Settings_Member_Categories.reset',
    sections: 'Settings_Member_Categories.sections',
    isMerged: 'Settings_Member_Categories.ismerged',
    actions: 'Settings_Member_Categories.actions',
    selectLanguage: 'Settings_Member_Categories.selectlanguage',
    isHasFiles: 'Settings_Member_Categories.ishasfiles',
    selectBranch: 'Settings_Member_Categories.selectbranch',
    createdDate: 'Settings_Member_Categories.createddate',
    updatedDate: 'Settings_Member_Categories.updateddate'
};
