import React from 'react';

// Icons
import { IconUser, IconUserCircle, IconUserPlus, IconBriefcase, IconUsers, IconId, IconSection } from '@tabler/icons';

// Constant
import { roles } from 'env/roles';
import { useTranslation } from 'react-i18next';
import { jobTitlesPageConstant } from 'constant/Translations/hr/job-titles-page';
import { MembersPageConstant } from 'constant/Translations/hr/members-page';
import { DataSourcePageConstant } from 'constant/Translations/settings/file-templates/data-source-page';
import { DataSourceRequestsPageConstant } from 'constant/Translations/settings/file-templates/data-source-requests-page';
import { PatientRecordsPageConstant } from 'constant/Translations/administrative-management/patient-management/patient-records';
import { MasterAppointmentSettingsPageConstant } from 'constant/Translations/administrative-management/master-appointment-settings';
import { SectionsManagementPageConstant } from 'constant/Translations/system-administrator/sections-management-page';
import { AdministrationAccountsPageConstant } from 'constant/Translations/administration/account-page';

const icons = { IconUserCircle, IconUserPlus, IconUser, IconBriefcase, IconUsers, IconId, IconSection };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const useAdministrativeManagement = () => {
    const { t } = useTranslation();
    return {
        id: 'administrative-management',
        title: 'Administrative Management',
        type: 'group',
        caption: 'Administrative Management',
        icon: <IconUser />,
        role: [roles.Admin],
        children: [
            {
                id: 'patient-management',
                title: t(PatientRecordsPageConstant.patientManagement),
                type: 'group',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                children: [
                    {
                        id: 'patient-records',
                        title: t(PatientRecordsPageConstant.patientRecords),
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/patient-records',
                        breadcrumbs: false
                    },
                    {
                        id: 'reports-recommendation',
                        title: 'Reports and Recommendation',
                        type: 'group',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        children: [
                            {
                                id: 'surgical-operation-reports',
                                title: 'Surgical Operation Reports',
                                type: 'item',
                                icon: icons.IconUserCircle,
                                role: [roles.Admin],
                                url: '/surgical-operation-reports',
                                breadcrumbs: false
                            },
                            {
                                id: 'physician-recommendations',
                                title: 'Physician Recommendations',
                                type: 'item',
                                icon: icons.IconUserCircle,
                                role: [roles.Admin],
                                url: '/physician-recommendations',
                                breadcrumbs: false
                            },
                            {
                                id: 'test-and-laboratory-reports',
                                title: 'Test and Laboratory Reports',
                                type: 'item',
                                icon: icons.IconUserCircle,
                                role: [roles.Admin],
                                url: '/test-and-laboratory-reports',
                                breadcrumbs: false
                            },
                            {
                                id: 'radiology-reports',
                                title: 'Radiology Reports',
                                type: 'item',
                                icon: icons.IconUserCircle,
                                role: [roles.Admin],
                                url: '/radiology-reports',
                                breadcrumbs: false
                            }
                        ]
                    },
                    {
                        id: 'referrals',
                        title: 'Referrals',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/referrals',
                        breadcrumbs: false
                    },
                    {
                        id: 'prescriptions',
                        title: 'Prescriptions',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/prescriptions',
                        breadcrumbs: false
                    },
                    {
                        id: 'discharge-forms',
                        title: 'Discharge Forms',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/discharge-forms',
                        breadcrumbs: false
                    },
                    {
                        id: 'patient-files-appendix',
                        title: 'Patient Files Appendix',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/patient-files-appendix',
                        breadcrumbs: false
                    }
                ]
            },

            {
                id: 'human-resources-management',
                title: 'Human Resources Management',
                type: 'group',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                children: [
                    {
                        id: 'functional-departments',
                        title: t(SectionsManagementPageConstant.sections),
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/sections',
                        breadcrumbs: false
                    },
                    {
                        id: 'job-titles',
                        title: t(jobTitlesPageConstant.jobTitles),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        url: '/job-titles',
                        breadcrumbs: false,
                        role: [roles.Admin]
                    },
                    {
                        id: 'members',
                        title: t(MembersPageConstant.members),
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/members',
                        breadcrumbs: false
                    },
                    {
                        id: 'healthcare-professionals',
                        title: 'Healthcare Professionals',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/healthcare-professionals',
                        breadcrumbs: false
                    },
                    {
                        id: 'member-accounts',
                        title: t(AdministrationAccountsPageConstant.accounts),
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/member-accounts',
                        breadcrumbs: false
                    },
                    {
                        id: 'patient-accounts',
                        title: 'Patient Accounts',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/patient-accounts',
                        breadcrumbs: false
                    }
                ]
            },

            {
                id: 'quality-management-and-strategic-planning',
                title: 'Quality Management and Strategic Planning',
                type: 'group',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                children: [
                    {
                        id: 'member-and-patient-numbering',
                        title: 'Member and Patient Numbering',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/member-and-patient-numbering',
                        breadcrumbs: false
                    },
                    {
                        id: 'functional-classification',
                        title: 'Functional Classification',
                        type: 'item',
                        icon: icons.IconBriefcase,
                        url: '/member-categories',
                        breadcrumbs: false,
                        role: [roles.Admin]
                    },
                    {
                        id: 'administrative-decisions',
                        title: 'Administrative Decisions',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/administrative-decisions',
                        breadcrumbs: false
                    },
                    {
                        id: 'administrative-goals',
                        title: 'Administrative Goals',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/administrative-goals',
                        breadcrumbs: false
                    },
                    {
                        id: 'administrative-tasks',
                        title: 'Administrative Tasks',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/administrative-tasks',
                        breadcrumbs: false
                    },
                    {
                        id: 'Evaluation',
                        title: 'Evaluation',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/Evaluation',
                        breadcrumbs: false
                    },
                    {
                        id: 'member-files-group',
                        title: 'Member Files',
                        type: 'group',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        children: [
                            {
                                id: 'member-files',
                                title: 'Member Files',
                                type: 'item',
                                icon: icons.IconUserCircle,
                                role: [roles.Admin],
                                url: '/file-template',
                                breadcrumbs: false
                            },
                            {
                                id: 'evaluation-files',
                                title: 'Evaluation Files',
                                type: 'item',
                                icon: icons.IconUserCircle,
                                role: [roles.Admin],
                                url: '/evaluation-files',
                                breadcrumbs: false
                            },
                            {
                                id: 'dataSources',
                                title: t(DataSourcePageConstant.dataSources),
                                type: 'item',
                                icon: icons.IconSection,
                                url: '/dataSources',
                                breadcrumbs: false
                            },
                            {
                                id: 'dataSources-requests',
                                title: t(DataSourceRequestsPageConstant.dataSourceRequests),
                                type: 'item',
                                icon: icons.IconSection,
                                url: '/dataSources-requests',
                                breadcrumbs: false
                            }
                        ]
                    }
                ]
            },

            {
                id: 'quality-management-and-continuous-improvement',
                title: 'Quality Management and Continuous Improvement',
                type: 'group',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                children: [
                    {
                        id: 'quality-management-objectives',
                        title: 'Quality Management Objectives',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/quality-management-objectives',
                        breadcrumbs: false
                    },
                    {
                        id: 'quality-standards',
                        title: 'Quality Standards',
                        type: 'item',
                        icon: icons.IconBriefcase,
                        url: '/quality-standards',
                        breadcrumbs: false,
                        role: [roles.Admin]
                    },
                    {
                        id: 'quality-monitoring',
                        title: 'Quality Monitoring',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/quality-monitoring',
                        breadcrumbs: false
                    },
                    {
                        id: 'data-nalysis',
                        title: 'Data Analysis',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/data-nalysis',
                        breadcrumbs: false
                    },
                    {
                        id: 'process-improvement',
                        title: 'Process Improvement',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/process-improvement',
                        breadcrumbs: false
                    },
                    {
                        id: 'physician-job-classification',
                        title: 'Physician Job Classification',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/physician-job-classification',
                        breadcrumbs: false
                    },
                    {
                        id: 'healthcare-assistants',
                        title: 'Healthcare Assistants',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/Healthcare Assistants',
                        breadcrumbs: false
                    },
                    {
                        id: 'referrals',
                        title: 'Referrals',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/referrals',
                        breadcrumbs: false
                    },
                    {
                        id: 'patient-file-appendices',
                        title: 'Patient File Appendices',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/patient-file-appendices',
                        breadcrumbs: false
                    },
                    {
                        id: 'patient-files',
                        title: 'Patient Files',
                        type: 'group',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        children: [
                            {
                                id: 'patient-files-and-appendices',
                                title: 'Patient Files and Appendices',
                                type: 'item',
                                icon: icons.IconUserCircle,
                                role: [roles.Admin],
                                url: '/patient-files-and-appendices',
                                breadcrumbs: false
                            },
                            {
                                id: 'dataSources',
                                title: t(DataSourcePageConstant.dataSources),
                                type: 'item',
                                icon: icons.IconSection,
                                url: '/dataSources',
                                breadcrumbs: false
                            },
                            {
                                id: 'dataSources-requests',
                                title: t(DataSourceRequestsPageConstant.dataSourceRequests),
                                type: 'item',
                                icon: icons.IconSection,
                                url: '/dataSources-requests',
                                breadcrumbs: false
                            }
                        ]
                    }
                ]
            },

            {
                id: 'scientific-research-and-innovation-management',
                title: 'Scientific Research and Innovation Management',
                type: 'group',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                children: [
                    {
                        id: 'scientific-research',
                        title: 'Scientific Research',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/scientific-research',
                        breadcrumbs: false
                    }
                ]
            },

            {
                id: 'medical-training-professional-development-management',
                title: 'Medical Training and Professional Development Management',
                type: 'group',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                children: [
                    {
                        id: 'training-programs',
                        title: 'Training Programs',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/training-programs',
                        breadcrumbs: false
                    },
                    {
                        id: 'workshops',
                        title: 'Workshops',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/workshops',
                        breadcrumbs: false
                    },
                    {
                        id: 'educational-courses',
                        title: 'Educational Courses',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/educational-courses',
                        breadcrumbs: false
                    }
                ]
            },

            {
                id: 'logistics-procurement-management',
                title: 'Logistics and Procurement Management',
                type: 'group',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                children: [
                    {
                        id: 'supply-and-distribution-management',
                        title: 'Supply and Distribution Management',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/supply-distribution-management',
                        breadcrumbs: false
                    },
                    {
                        id: 'inventory-purchasing-management',
                        title: 'Inventory and Purchasing Management',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/inventory-purchasing-management',
                        breadcrumbs: false
                    },
                    {
                        id: 'transportation-internal-transport-management',
                        title: 'Transportation and Internal Transport Management',
                        type: 'item',
                        icon: icons.IconUserCircle,
                        role: [roles.Admin],
                        url: '/transportation-internal-transport-management',
                        breadcrumbs: false
                    }
                ]
            },

            {
                id: 'food-and-nutrition-services-management',
                title: 'Food and Nutrition Services Management',
                type: 'item',
                icon: icons.IconUserCircle,
                role: [roles.Admin],
                url: '/food-nutrition-services-management',
                breadcrumbs: false
            },
            {
                id: 'marketing-and-advertising',
                title: 'Marketing and Advertising',
                type: 'item',
                icon: icons.IconUserCircle,
                role: [roles.Admin],
                url: '/marketing-and-advertising',
                breadcrumbs: false
            },
            {
                id: 'master-appointment-settings',
                title: t(MasterAppointmentSettingsPageConstant.masterAppointmentSettings),
                type: 'item',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                url: '/appointment-settings',
                breadcrumbs: false
            }
        ]
    };
};
export default useAdministrativeManagement;
