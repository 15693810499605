export const PatientAppointmentsPageConstant = {
    patientAppointments: 'Reception_Management_Patient_Appointments.patientappointments',
    typeAppointments: 'Reception_Management_Patient_Appointments.typeappointments',
    visits: 'Reception_Management_Patient_Appointments.visits',
    patientAppointment: 'Reception_Management_Patient_Appointments.patientappointment',
    patient: 'Reception_Management_Patient_Appointments.patient',
    appointmentTypes: 'Reception_Management_Patient_Appointments.appointmenttypes',
    reservationTypes: 'Reception_Management_Patient_Appointments.reservationtypes',
    medicalVisitTypes: 'Reception_Management_Patient_Appointments.medicalvisittypes',
    doctor: 'Reception_Management_Patient_Appointments.doctor',
    doctors: 'Reception_Management_Patient_Appointments.doctors',
    appointments: 'Reception_Management_Patient_Appointments.appointments',
    complaint: 'Reception_Management_Patient_Appointments.complaint',
    code: 'Reception_Management_Patient_Appointments.code',
    isWaitting: 'Reception_Management_Patient_Appointments.iswaitting',
    fileNumber: 'Reception_Management_Patient_Appointments.filenumber',
    patientName: 'Reception_Management_Patient_Appointments.patientname',
    actions: 'Reception_Management_Patient_Appointments.actions',
    addPatient: 'Reception_Management_Patient_Appointments.addpatient',
    createdDate: 'Reception_Management_Patient_Appointments.createddate',
    updatedDate: 'Reception_Management_Patient_Appointments.updateddate',
    externalReferral: 'Reception_Management_Patient_Appointments.externalreferral',
    internalReferral: 'Reception_Management_Patient_Appointments.internalreferral',
    addNewExternal: 'Reception_Management_Patient_Appointments.addnewexternal',
    addExternalFromList: 'Reception_Management_Patient_Appointments.addexternalfromlist',
    name: 'Reception_Management_Patient_Appointments.name',
    description: 'Reception_Management_Patient_Appointments.description',
    icon: 'Reception_Management_Patient_Appointments.icon',
    selectAppointment: 'Reception_Management_Patient_Appointments.selectappointment'
};
