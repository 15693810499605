export const ReligionPageConstant = {
    religions: 'Settings_Profile_Religion.religions',
    religion: 'Settings_Profile_Religion.religion',
    name: 'Settings_Profile_Religion.name',
    code: 'Settings_Profile_Religion.code',
    actions: 'Settings_Profile_Religion.actions',
    edit: 'Settings_Profile_Religion.edit',
    delete: 'Settings_Profile_Religion.delete',
    createdDate: 'Settings_Profile_Religion.createddate',
    updatedDate: 'Settings_Profile_Religion.updateddate'
};
