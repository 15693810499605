export const AccountPageConstant = {
    accounts: 'System_Administrator_Accounts.accounts',
    account: 'System_Administrator_Accounts.account',
    username: 'System_Administrator_Accounts.username',
    accountName: 'System_Administrator_Accounts.accountname',
    email: 'System_Administrator_Accounts.email',
    resetPassword: 'System_Administrator_Accounts.resetpassword',
    password: 'System_Administrator_Accounts.password',
    member: 'System_Administrator_Accounts.member',
    activationFrom: 'System_Administrator_Accounts.activationfrom',
    activationTo: 'System_Administrator_Accounts.activationto',
    isActive: 'System_Administrator_Accounts.isactive',
    newPassword: 'System_Administrator_Accounts.newpassword',
    confirmedNewPassword: 'System_Administrator_Accounts.confirmednewpassword',
    cancel: 'System_Administrator_Accounts.cancel',
    reset: 'System_Administrator_Accounts.reset',
    edit: 'System_Administrator_Accounts.edit',
    delete: 'System_Administrator_Accounts.delete',
    actions: 'System_Administrator_Accounts.actions',
    viewAccounts: 'System_Administrator_Accounts.viewaccounts',
    memberAccounts: 'System_Administrator_Accounts.memberaccounts',
    createdDate: 'System_Administrator_Accounts.createddate',
    updatedDate: 'System_Administrator_Accounts.updateddate'
};
