import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authAxios } from 'config/axios';
import { GET_STATIC_DATA, LANGUAGE } from 'env/server';

const initialState = {
    loading: false,
    error: false,
    languages: [],
    data: {
        privileges: []
    },
    activeLanguage: 'en',
    searchInTable: '',
    openAddDialogDataGrid: false,
    openEditDialogDataGrid: false,
    dataGridInfo: {},
    patient: {}
};
export const getAllStaticData = createAsyncThunk('static/getStatic', async () => {
    return authAxios.get(GET_STATIC_DATA).then((res) => res.data?.data);
});

export const getLanguages = createAsyncThunk('static/getLanguages', async () => {
    return authAxios.get(LANGUAGE.GET).then((res) => res?.data?.data);
});

export const staticSlice = createSlice({
    name: 'static',
    initialState,
    reducers: {
        changeLanguage: (state, action) => {
            state.activeLanguage = action.payload;
        },
        setLanguages: (state, action) => {
            state.languages = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },

        setSearchInTable: (state, action) => {
            state.searchInTable = action.payload;
        },
        setOpenAddDialogDataGrid: (state, action) => {
            state.openAddDialogDataGrid = action.payload;
        },
        setDataGridInfo: (state, action) => {
            state.dataGridInfo = action.payload;
        },
        setOpenEditDialogDataGrid: (state, action) => {
            state.openEditDialogDataGrid = action.payload;
        },
        setPatient: (state, action) => {
            state.patient = action.payload;
        },
        setBranchInfo: (state, action) => {
            state.branchInfo = action.payload;
        },
        setCenterInfo: (state, action) => {
            state.centerInfo = action.payload;
        }
    },

    extraReducers: (builder) => {
        function onError(state, action) {
            state.loading = false;
            state.error = action.payload;
        }

        function onPending(state, action) {
            state.loading = true;
        }
        function onSuccess(state, action) {
            state.loading = false;
            state.error = null;
        }
        //getAllStaticData
        builder.addCase(getAllStaticData.pending, onPending);
        builder.addCase(getAllStaticData.fulfilled, (state, action) => {
            state.data = action.payload;

            state.loading = false;
            state.error = null;
        });
        builder.addCase(getAllStaticData.rejected, onError);

        //getLanguages
    }
});

export const {
    changeLanguage,
    setLanguages,
    setData,
    setSearchInTable,
    setOpenAddDialogDataGrid,
    setOpenEditDialogDataGrid,
    setDataGridInfo,
    setPatient,
    setBranchInfo,
    setCenterInfo
} = staticSlice.actions;

export default staticSlice.reducer;
