export const RoomPageConstant = {
    rooms: 'Reception_Management_Room.rooms',
    room: 'Reception_Management_Room.room',
    name: 'Reception_Management_Room.name',
    icon: 'Reception_Management_Room.icon',
    image: 'Reception_Management_Room.image',
    description: 'Reception_Management_Room.description',
    title: 'Reception_Management_Room.title',
    floor: 'Reception_Management_Room.floor',
    code: 'Reception_Management_Room.code',
    isActive: 'Reception_Management_Room.isactive',
    info: 'Reception_Management_Room.info',
    uploadIcon: 'Reception_Management_Room.uploadicon',
    edit: 'Reception_Management_Room.edit',
    delete: 'Reception_Management_Room.delete',
    actions: 'Reception_Management_Room.actions',
    search: 'Reception_Management_Room.search',
    reset: 'Reception_Management_Room.reset',
    selectLanguage: 'Reception_Management_Room.selectlanguage',
    createdDate: 'Reception_Management_Room.createddate',
    updatedDate: 'Reception_Management_Room.updateddate'
};
