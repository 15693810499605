export const TranslationsPageConstant = {
    translations: 'Languages_Translations.translations',
    translation: 'Languages_Translations.translation',
    key: 'Languages_Translations.key',
    platform: 'Languages_Translations.platform',
    pageKey: 'Languages_Translations.pagekey',
    thesaurus: 'Languages_Translations.thesaurus',
    values: 'Languages_Translations.values',
    code: 'Languages_Translations.code',
    edit: 'Languages_Translations.edit',
    actions: 'Languages_Translations.actions',
    delete: 'Languages_Translations.delete',
    selectLanguage: 'Languages_Translations.selectlanguage',
    reset: 'Languages_Translations.reset',
    search: 'Languages_Translations.search',
    createdDate: 'Languages_Translations.createddate',
    updatedDate: 'Languages_Translations.updateddate'
};
