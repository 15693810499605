export const ExternalPageConstant = {
    externals: 'Reception_Management_External.externals',
    external: 'Reception_Management_External.external',
    mainExternals: 'Reception_Management_External.mainexternals',
    pendingExternals: 'Reception_Management_External.pendingexternals',
    name: 'Reception_Management_External.name',
    member: 'Reception_Management_External.member',
    code: 'Reception_Management_External.code',
    icon: 'Reception_Management_External.icon',
    count: 'Reception_Management_External.count',
    description: 'Reception_Management_External.description',
    confirm: 'Reception_Management_External.confirm',
    replace: 'Reception_Management_External.replace',
    replaceExternal: 'Reception_Management_External.replaceexternal',
    createdDate: 'Reception_Management_External.createddate',
    updatedDate: 'Reception_Management_External.updateddate',
    uniqueCode: 'Reception_Management_External.uniquecode'
};
