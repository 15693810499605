export const MASTER = {
    Admin: 0,
    Center: 1,
    Branch: 2,
    Section: 3
};

export const MASTER_NAME = {
    Admin: 'Admin',
    Center: 'Center',
    Branch: 'Branch',
    Section: 'Section'
};
