export const ExternalReferralsPageConstant = {
    externalReferrals: 'Reception_Management_External_Referrals.externalreferrals',
    externalReferral: 'Reception_Management_External_Referrals.externalreferral',
    mainExternalReferrals: 'Reception_Management_External_Referrals.mainexternalreferrals',
    pendingExternalReferrals: 'Reception_Management_External_Referrals.pendingexternalreferrals',
    name: 'Reception_Management_External_Referrals.name',
    member: 'Reception_Management_External_Referrals.member',
    code: 'Reception_Management_External_Referrals.code',
    icon: 'Reception_Management_External_Referrals.icon',
    count: 'Reception_Management_External_Referrals.count',
    description: 'Reception_Management_External_Referrals.description',
    confirm: 'Reception_Management_External_Referrals.confirm',
    replace: 'Reception_Management_External_Referrals.replace',
    replaceExternalReferral: 'Reception_Management_External_Referrals.replaceexternalreferral',
    save: 'Reception_Management_External_Referrals.save',
    cancel: 'Reception_Management_External_Referrals.cancel',
    uniqueCode: 'Reception_Management_External_Referrals.uniquecode',
    actions: 'Reception_Management_External_Referrals.actions',
    edit: 'Reception_Management_External_Referrals.edit',
    delete: 'Reception_Management_External_Referrals.delete',
    createdDate: 'Reception_Management_External_Referrals.createddate',
    updatedDate: 'Reception_Management_External_Referrals.updateddate',
    isActive: 'Reception_Management_External_Referrals.isactive'
};
