import React from 'react';

// MUI Components
import Switch from '@mui/material/Switch';

// UI Components
import BooleanFilter from 'ui-component/DataGrid/BooleanFilter';

// 3rd party Components
import { get } from 'lodash';
import i18next from 'i18next';
import { CommonPageConstant } from 'constant/Translations/common';

export const switchCell = ({
    accessorKey = 'isActive',
    disabledKey = 'isDefault',
    disabledFunc = null,
    size = 180,
    onChange = ({ checked, event }) => {}
}) => ({
    accessorKey,
    header: i18next.t(CommonPageConstant.activation),
    size: size,
    Cell: ({ row }) => (
        <Switch
            checked={get(row.original, accessorKey)}
            disabled={disabledFunc ? disabledFunc(row.original) : get(row.original, disabledKey)}
            onChange={(event) => onChange({ checked: event.target.checked, data: row.original })}
            inputProps={{ 'aria-label': '' }}
        />
    ),
    accessorFn: (row) => Boolean(row.isActive),
    sortingFn: 'boolean',
    Filter: BooleanFilter
});

export const SwitchTree = ({
    data,
    accessorKey = 'isActive',
    disabledKey = 'isDefault',
    disabledFunc = null,
    onChange = ({ checked, event }) => {}
}) => {
    return (
        <Switch
            checked={get(data, accessorKey)}
            disabled={disabledFunc ? disabledFunc(data) : get(data, disabledKey)}
            onChange={(event) => onChange({ checked: event.target.checked, data })}
            inputProps={{ 'aria-label': '' }}
        />
    );
};
