export const CityPageConstant = {
    cities: 'Settings_Address_Cities.cities',
    city: 'Settings_Address_Cities.city',
    name: 'Settings_Address_Cities.name',
    country: 'Settings_Address_Cities.country',
    state: 'Settings_Address_Cities.state',
    code: 'Settings_Address_Cities.code',
    edit: 'Settings_Address_Cities.edit',
    actions: 'Settings_Address_Cities.actions',
    delete: 'Settings_Address_Cities.delete',
    createdDate: 'Settings_Address_Cities.createddate',
    updatedDate: 'Settings_Address_Cities.updateddate'
};
