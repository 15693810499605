// export const API_URL = `https://api.elcdt.com/api`;
// export const STATIC_FILES_URL = `https://api.elcdt.com`;
// export const DEVELOP_OR_PRODUCTION = {
//     PRODUCTION: true,
//     DEVELOP: false
// }

export const API_URL = `https://sw.elcdt.com/api`;
export const STATIC_FILES_URL = `https://sw.elcdt.com`;
export const DEVELOP_OR_PRODUCTION = {
    PRODUCTION: false,
    DEVELOP: true
};

export const UPLOAD_FILE = `${API_URL}/Setting/Attachment/DynamicUpload`;
export const MEMBER_ADD_ACCOUNT = `${API_URL}/Main/Member/AddAccount`;
export const MEMBER_UPDATE_ACCOUNT = `${API_URL}/Main/Member/UpdateAccount`;
//auth
export const AUTH = {
    LOGIN: `${API_URL}/Security/SignIn`
};
export const GET_STATIC_DATA = `${API_URL}/Common/Lookup/Index`;
export const SWAGGER_JSON = `${STATIC_FILES_URL}/swagger/v1/swagger.json`;
export const ENUMS = `${API_URL}/Utils/GetEnumsDescriptor`;
// History
export const HISTORY = {
    GET: `${API_URL}/Main/History/GetHistoryList`
};
// Accounts
export const ACCOUNT = {
    DELETE: `${API_URL}/Security/Delete`,
    EDIT: `${API_URL}/Security/Update`,
    ADD: `${API_URL}/Security/Add`,
    GET: `${API_URL}/Security/Read`,
    EDIT_ROLE: `${API_URL}/Security/Role/Modify`,
    UPDATE_ACTIVATION: `${API_URL}/Security/UpdateActivation`,
    CONFRIM_ACCOUNT: `${API_URL}/Security/UserAccount/ConfirmAccount`
};
// LANGUAGE
export const LANGUAGE = {
    GET: `${API_URL}/Setting/Language/Get`,
    ADD: `${API_URL}/Setting/Language/Add`,
    EDIT: `${API_URL}/Setting/Language/Update`,
    DELETE: `${API_URL}/Setting/Language/Delete`,
    GET_ACTIVE_LANGUAGES: `${API_URL}/Setting/Language/GetActiveLanguages`,
    TOGGLE_ACTIVE_LANGUAGES: `${API_URL}/Setting/Language/ToggleActiveLanguage`
};
export const DEFAULT_LANGUAGES = {
    GET: `${API_URL}/Setting/DefaultLanguage/Get`,
    GET_ACTIVE_LANGUAGES: `${API_URL}/Setting/DefaultLanguage/GetActiveLanguages`
};
// SYSTEM_LANGUAGE
export const SYSTEM_LANGUAGE = {
    GET: `${API_URL}/Setting/SystemLanguage/Get`,
    ADD: `${API_URL}/Setting/SystemLanguage/Add`,
    EDIT: `${API_URL}/Setting/SystemLanguage/Update`,
    DELETE: `${API_URL}/Setting/SystemLanguage/Delete`
};

// SECTIONS

export const SECTION = {
    GET: `${API_URL}/Main/Section/Get`,
    ADD: `${API_URL}/Main/Section/Add`,
    EDIT: `${API_URL}/Main/Section/Update`,
    DELETE: `${API_URL}/Main/Section/Delete`,
    GET_HIERARCHICAL: `${API_URL}/Main/Section/GetGetHierarchicalSections`,
    UPDATE_ACTIVATION: `${API_URL}/Main/Section/UpdateActivition`
};
// COUNTRY
export const COUNTRY = {
    GET: `${API_URL}/Common/Country/Get`,
    GET_STATES: `${API_URL}/Common/Country/GetStates`,
    ADD: `${API_URL}/Common/Country/Add`,
    EDIT: `${API_URL}/Common/Country/Update`,
    DELETE: `${API_URL}/Common/Country/Delete`
};

// CITY
export const CITY = {
    GET: `${API_URL}/Common/City/GetAll`,
    ADD: `${API_URL}/Common/City/Add`,
    EDIT: `${API_URL}/Common/City/Update`,
    DELETE: `${API_URL}/Common/City/Delete`
};
// STATE
export const STATE = {
    GET: `${API_URL}/Common/State/GetAll`,
    GET_CITIES: `${API_URL}/Common/State/GetCites`,
    ADD: `${API_URL}/Common/State/Add`,
    EDIT: `${API_URL}/Common/State/Update`,
    DELETE: `${API_URL}/Common/State/Delete`
};

// Education
export const EDUCATION = {
    GET: `${API_URL}/Common/Education/Get`,
    ADD: `${API_URL}/Common/Education/Add`,
    EDIT: `${API_URL}/Common/Education/Update`,
    DELETE: `${API_URL}/Common/Education/Delete`
};

// Religion
export const RELIGION = {
    GET: `${API_URL}/Common/Religion/Get`,
    ADD: `${API_URL}/Common/Religion/Add`,
    EDIT: `${API_URL}/Common/Religion/Update`,
    DELETE: `${API_URL}/Common/Religion/Delete`
};

// Doctrine
export const DOCTRINE = {
    GET: `${API_URL}/Common/Doctrine/Get`,
    ADD: `${API_URL}/Common/Doctrine/Add`,
    EDIT: `${API_URL}/Common/Doctrine/Update`,
    DELETE: `${API_URL}/Common/Doctrine/Delete`
};

// Datasource
export const DATASOURCE = {
    GET: `${API_URL}/Main/DataSource/Get`,
    ADD: `${API_URL}/Main/DataSource/Add`,
    EDIT: `${API_URL}/Main/DataSource/Update`,
    DELETE: `${API_URL}/Main/DataSource/Delete`,
    GET_DATA_SOURCE_FILES: `${API_URL}/Main/DataSource/GetDataSourceFiles`,
    CONFRIM: `${API_URL}/Main/DataSource/ConfirmManuallyAddedDataSource`,
    DENY: `${API_URL}/Main/DataSource/DenyManuallyAddedDataSource`,
    MERGE: `${API_URL}/Main/DataSource/MergeManuallyAddedDataSource`,
    GET_NEWLY_ADDED_OPTIONS: `${API_URL}/Main/DataSource/GetNewlyAddedOptions`,
    ADD_NEW_OPTION_TO_DATASOURCE_OPTIONS: `${API_URL}/Main/DataSource/AddNewOptionToDatasourceOptions`,
    GET_BY_KEY: `${API_URL}/Main/DataSource/GetDataSourceByKey`
};

// Doctrine
export const DEPARTMENT = {
    GET: `${API_URL}/Common/Department/Get`,
    ADD: `${API_URL}/Common/Department/Add`,
    EDIT: `${API_URL}/Common/Department/Update`,
    DELETE: `${API_URL}/Common/Department/Delete`
};

// MARITAL_STATUS
export const MARITAL_STATUS = {
    GET: `${API_URL}/Main/MaritalStatus/Get`,
    ADD: `${API_URL}/Main/MaritalStatus/Add`,
    EDIT: `${API_URL}/Main/MaritalStatus/Update`,
    DELETE: `${API_URL}/Main/MaritalStatus/Delete`
};
// NICK_NAME
export const NICK_NAME = {
    GET: `${API_URL}/Common/Nickname/Get`,
    ADD: `${API_URL}/Common/Nickname/Add`,
    EDIT: `${API_URL}/Common/Nickname/Update`,
    DELETE: `${API_URL}/Common/Nickname/Delete`
};
// NICK_NAME

export const SOCIAL_MEDIA = {
    GET: `${API_URL}/Common/SocialMedia/Get`,
    ADD: `${API_URL}/Common/SocialMedia/Add`,
    EDIT: `${API_URL}/Common/SocialMedia/Update`,
    DELETE: `${API_URL}/Common/SocialMedia/Delete`
};
//BRANCH
export const BRANCH = {
    ADD: `${API_URL}/Security/Branch/Create`,
    GET: `${API_URL}/Main/Branch/Get`,
    EDIT: `${API_URL}/Security/Branch/Update`,
    DELETE: `${API_URL}/Security/Branch/Delete`,
    ASSIGN_JOB_TITLES_TO_BRANCH: `${API_URL}/Main/Branch/AssignJobTitle`,
    UN_ASSIGN_JOB_TITLES_TO_BRANCH: `${API_URL}/Main/Branch/RemoveJobTitleAsignee`
};

//CENTERS

export const CENTER = {
    ADD: `${API_URL}/Security/Center/Create`,
    GET: `${API_URL}/Main/Center/Get`,
    EDIT: `${API_URL}/Security/Center/Update`,
    DELETE: `${API_URL}/Security/Center/Delete`,
    EDIT_CENTER_TITLE: `${API_URL}/Main/Center/UpdateTitle`,
    EDIT_CENTER_AFFILIATED_WITH: `${API_URL}/Main/Center/UpdateAffiliatedWith`,
    EDIT_CENTER_ABOUT_US: `${API_URL}/Main/Center/UpdateAboutUs`,
    EDIT_CENTER_Theme: `${API_URL}/Main/Center/UpdateTheme`,
    EDIT_CENTER_COUNTRY_ID: `${API_URL}/Main/Center/UpdateCountryId`,
    EDIT_CENTER_STATE_ID: `${API_URL}/Main/Center/UpdateStateId`,
    EDIT_CENTER_CITY_ID: `${API_URL}/Main/Center/UpdateCityId`,
    EDIT_CENTER_AREA: `${API_URL}/Main/Center/UpdateArea`,
    EDIT_CENTER_ADDRESS: `${API_URL}/Main/Center/UpdateAddress`,
    EDIT_CENTER_HAS_GLOBAL_LOCATION: `${API_URL}/Main/Center/UpdateHasGlobalLocation`,
    EDIT_CENTER_SECTOR: `${API_URL}/Main/Center/UpdateSector`,
    EDIT_CENTER_WEBSITE: `${API_URL}/Main/Center/UpdateWebSite`,
    EDIT_CENTER_EMAIL_ADDRESS: `${API_URL}/Main/Center/UpdateEmailAddress`,
    EDIT_CENTER_CONTACT_NUMBERS: `${API_URL}/Main/Center/UpdateContactNumbers`,
    REMOVE_CENTER_COMBINE_SECTION: `${API_URL}/Main/Center/RemoveCombineSection`,
    EDIT_CENTER_SOCIAL: `${API_URL}/Main/Center/UpdateSocialMedias`,
    EDIT_CENTER_LANGUAGES: `${API_URL}/Main/Center/UpdateLanguages`,
    EDIT_CENTER_MemberKindTypeNumberingPatterns: `${API_URL}/Main/Center/UpdateMemberKindTypeNumberingPatterns`,
    EDIT_CENTER_SECTION_REFRENCES: `${API_URL}/Main/Center/UpdateSectionReferences`,
    EDIT_CENTER_COMBINE_SECTIONS: `${API_URL}/Main/Center/UpdateCombineSections`,
    EDIT_CENTER_OWNER: `${API_URL}/Main/Center/UpdateOwner`,
    // Center end points
    GET_SECTIONS: `${API_URL}/Main/Section/GetSections`,
    GET_CENTER_COMBINES: `${API_URL}/Main/Section/GetCombineSections`
};

// JOB_TITLE

export const JOB_TITLE = {
    GET: `${API_URL}/Setting/JobTitle/GetAllJobTitles/Get`,
    ADD: `${API_URL}/Setting/JobTitle/Add`,
    EDIT: `${API_URL}/Setting/JobTitle/Update`,
    DELETE: `${API_URL}/Setting/JobTitle/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/Setting/JobTitle/UpdateActivition`,
    GET_HIERARCHICAL: `${API_URL}/Setting/JobTitle/GetHierarchicalJobTitles`
};

// Memebers
export const MEMBER = {
    GET: `${API_URL}/Main/Member/Get`,
    ADD: `${API_URL}/Main/Member/Add`,
    EDIT: `${API_URL}/Main/Member/Update`,
    DELETE: `${API_URL}/Main/Member/Delete`,
    ASSIGN_JOB_TITLE: `${API_URL}/Main/Member/AssignJobTitle`,
    ASSIGN_MEMBER_CATEGORY: `${API_URL}/Main/Member/UpdateMemberCategoriesForMember`,
    REMOVE_JOB_TITLE_ASIGNEE: `${API_URL}/Main/Member/RemoveJobTitleAsignee`,
    GET_MEMBER_CARD: `${API_URL}/Main/Member/GetMemberCard`,
    ADD_CONTACT_FILE: `${API_URL}/Main/Member/AddContactFile`,
    ADD_CAREER_FILE: `${API_URL}/Main/Member/AddCareerFile`,
    GET_PERSONAL_FILE: `${API_URL}/Main/Member/GetPersonalFile`,
    GET_CONTACT_FILE: `${API_URL}/Main/Member/GetContactFile`,
    GET_CAREER_FILE: `${API_URL}/Main/Member/GetCurrentJobTitlesForMember`,
    GET_GENERAL_CARRER_FILE: `${API_URL}/Main/Member/GetGeneralCarrerFile`,
    GET_SCIENTIFIC_FILE: `${API_URL}/Main/Member/GetScientificFile`,
    ADD_SCIENTIFIC_FILE: `${API_URL}/Main/Member/AddScientificFile`,
    UPDATE_SCIENTIFIC_FILE: `${API_URL}/Main/Member/UpdateScientificFile`,
    EDIT_PERSONAL_FILE: `${API_URL}/Main/Member/UpdatePersonalFile`,
    EDIT_CONTACT_FILE: `${API_URL}/Main/Member/UpdateContactFile`,
    UPDATE_ACCOUNT: `${API_URL}/Main/Member/UpdateAccount`,
    DELETE_ACCOUNT: `${API_URL}/Main/Member/DeleteAccount`,
    CONFRIM_UPDATE_ACCOUNT_EMAIL: `${API_URL}/Main/Member/ConfirmUpdateAccountEmail`,
    GET_MY_PROFILE: `${API_URL}/Main/Member/GetMyProfile`,
    UPDATE_MAIN_PHONE_NUMBER: `${API_URL}/Main/Member/UpdateMainPhoneNumber`,
    UPDATE_ACTIVATION: `${API_URL}/Main/Member/UpdateActivition`,
    DOCTORS_LIST: `${API_URL}/Main/Member/GetMemberDoctosList`
};

export const PROFESIONAL_FILE = {
    COURSE_OR_SKILL: {
        GET: `${API_URL}/member/ProfessionalFile/GetMemberCoursesAndSkills`,
        ADD: `${API_URL}/member/ProfessionalFile/AddCourseOrSkill`,
        EDIT: `${API_URL}/member/ProfessionalFile/UpdateCourseOrSkill`,
        DELETE: `${API_URL}/member/ProfessionalFile/DeleteCourseOrSkill`
    },
    EXPERIENCE: {
        GET: `${API_URL}/member/ProfessionalFile/GetMemberExperiances`,
        ADD: `${API_URL}/member/ProfessionalFile/AddExperiance`,
        EDIT: `${API_URL}/member/ProfessionalFile/UpdateExperiance`,
        DELETE: `${API_URL}/member/ProfessionalFile/DeleteExperiance`
    },
    POSTER: {
        GET: `${API_URL}/member/ProfessionalFile/GetMemberPosters`,
        ADD: `${API_URL}/member/ProfessionalFile/AddPoster`,
        EDIT: `${API_URL}/member/ProfessionalFile/UpdatePoster`,
        DELETE: `${API_URL}/member/ProfessionalFile/DeletePoster`
    }
};

// MEMBER CATEGORIES
export const MEMBER_CATEGORY = {
    GET: `${API_URL}/Setting/MemberCategory/GetAllCategories/Get`,
    ADD: `${API_URL}/Setting/MemberCategory/Add`,
    EDIT: `${API_URL}/Setting/MemberCategory/Update`,
    DELETE: `${API_URL}/Setting/MemberCategory/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/Setting/MemberCategory/UpdateActivition`,
    GET_HIERARCHICAL: `${API_URL}/Setting/MemberCategory/GetHierarchicalCategories`,
    GET_FULL_INFO: `${API_URL}/Setting/MemberCategory/GetFullInfoById`,
    ASSIGN_JOB_TITLES_TO_CATEGORY: `${API_URL}/Setting/MemberCategory/AssignJobTitlesToCategory`,
    UN_ASSIGN_JOB_TITLES_TO_CATEGORY: `${API_URL}/Setting/MemberCategory/UnAssignJobTitlesToCategory`
};

export const MERGED_MEMBER_CATEGORY = {
    GET: `${API_URL}/Setting/MemberCategory/GetMergedCategories`,
    ADD: `${API_URL}/Setting/MemberCategory/AddMergeCategories`,
    EDIT: `${API_URL}/Setting/MemberCategory/UpdateMergedCategories`,
    DELETE: `${API_URL}/Setting/MemberCategory/Delete`
};
// DYNAMIC FIELD

export const DYNAMIC_FIELD = {
    GET: `${API_URL}/Setting/DynamicField/Get`,
    ADD: `${API_URL}/Setting/DynamicField/Add`,
    EDIT: `${API_URL}/Setting/DynamicField/Update`,
    DELETE: `${API_URL}/Setting/DynamicField/Delete`
};
// DYNAMIC_FIELD

export const DYNAMIC_FIELD_GROUP = {
    GET: `${API_URL}/Setting/DynamicFieldGroup/Get`,
    ADD: `${API_URL}/Setting/DynamicFieldGroup/Add`,
    EDIT: `${API_URL}/Setting/DynamicFieldGroup/Update`,
    DELETE: `${API_URL}/Setting/DynamicFieldGroup/Delete`
};
// ACADEMIC
export const ACADEMIC = {
    GET: `${API_URL}/Common/Academic/Get`,
    ADD: `${API_URL}/Common/Academic/Add`,
    EDIT: `${API_URL}/Common/Academic/Update`,
    DELETE: `${API_URL}/Common/Academic/Delete`
};

// Decision
export const DECISION = {
    GET: `${API_URL}/Main/Center/GetDecision`,
    ADD: (center) => `${API_URL}/Main/Center/AddDecision/${center}`,
    EDIT: `${API_URL}/Main/Center/UpdateDecision`,
    DELETE: `${API_URL}/Main/Center/DeleteDecision`
};

// TASK
export const TASK = {
    GET_TASKS: `${API_URL}/Main/Center/GetTask`,
    ADD_TASK: (centerID) => `${API_URL}/Main/Center/AddTask/${centerID}`,
    EDIT_TASK: `${API_URL}/Main/Center/UpdateTask`,
    DELETE_TASK: `${API_URL}/Main/Center/DeleteTask`
};

// GOAL

export const GOAL = {
    GET_GOALS: `${API_URL}/Main/Center/GetGoal`,
    ADD_GOAL: (centerID) => `${API_URL}/Main/Center/AddGoal/${centerID}`,
    EDIT_GOAL: `${API_URL}/Main/Center/UpdateGoal`,
    DELETE_GOAL: `${API_URL}/Main/Center/DeleteGoal`
};

// Chat API
export const CHAT_URL = `https://api-chat.elcdt.com/api`;
export const CHAT_STATIC_FILES_URL = `https://api-chat.elcdt.com/`;
/* export const CHAT_URL = `https://api-chat-test.elcdt.com/api`;
export const CHAT_STATIC_FILES_URL = `https://api-chat-test.elcdt.com/`;
 */ export const GET_CHATS = `${CHAT_URL}/Main/Message/GetChats`;
export const GET_MESSAGES = (chatId, page, text) =>
    `${CHAT_URL}/Main/Message/GetMessages/${chatId}/?${page ? `page=${page}` : ''}${text ? `${page ? '&' : ''}text=${text}` : ''}`;
export const GET_CHAT_MEDIA = (chatId, type, page) =>
    `${CHAT_URL}/Main/Message/GetMessages/${chatId}?type=${type}${page ? `&page=${page}` : ''}`;
export const PIN_MESSAGE = (chatId, messageId) => `${CHAT_URL}/Main/Message/PinMessage/${chatId}/${messageId}`;
export const UN_PIN_MESSAGE = (chatId, messageId) => `${CHAT_URL}/Main/Message/UnPinMessage/${chatId}/${messageId}`;
export const STAR_MESSAGE = (messageId) => `${CHAT_URL}/Main/Message/StarMessage/${messageId}`;
export const UN_STAR_MESSAGE = (messageId) => `${CHAT_URL}/Main/Message/UnStarMessage/${messageId}`;
export const CHAT_UPLOAD_FILE = `${CHAT_URL}/Main/Message/Upload`;
export const CREATE_CHAT = `${CHAT_URL}/Main/Message/CreateChat`;
export const GET_STARS = `${CHAT_URL}/Main/Message/GetStars`;

// FileTemplates

export const FILE_TEMPLATE = {
    GET: `${API_URL}/Main/FileTemplate/GetItems/Get`,
    GET_LITE: `${API_URL}/Main/FileTemplate/GetLite/GetLite`,
    ADD: `${API_URL}/Main/FileTemplate/Add`,
    EDIT: `${API_URL}/Main/FileTemplate/Update`,
    DELETE: `${API_URL}/Main/FileTemplate/Delete`,
    GET_MEMBER_SELECTED_FILES: `${API_URL}/Main/FileTemplate/GetMemberCategoryTemplateFileByType`,
    ASSIGN_CATEGORY_FILE_TEMPLATE: `${API_URL}/Main/FileTemplate/AssignCategoryToFileTemplate`,
    UN_ASSIGN_CATEGORY_FILE_TEMPLATE: `${API_URL}/Main/FileTemplate/UnAssignCategoryToFileTemplate`
};

// Password

export const PASSWORD = {
    RESET_USING_TOKEN: `${API_URL}/Security/UserAccount/ResetPassword`,
    REQUEST_RESET: `${API_URL}/Security/UserAccount/RequestResetPassword`,
    RESET_BY_ADMIN: `${API_URL}/Security/ResetUserPassword`,
    RESET_BY_USER: `${API_URL}/Security/UpdateMyPassword`
};

export const ATTACH_REFERENCES = {
    label: 'Attachment References',
    GET: `${API_URL}/indexes/Enums/AttachReferences/Get`,
    EDIT: `${API_URL}/indexes/Enums/AttachReferences/Update`
};

export const BLOOD = {
    label: 'Blood',
    GET: `${API_URL}/indexes/Enums/Blood/Get`,
    EDIT: `${API_URL}/indexes/Enums/Blood/Update`
};
export const DATASOURCE_TYPE = {
    label: 'DataSource Type',
    GET: `${API_URL}/indexes/Enums/DataSourceType/Get`,
    EDIT: `${API_URL}/indexes/Enums/DataSourceType/Update`
};
export const EDUCATIONAL_LEVEL = {
    label: 'Educational Level',
    GET: `${API_URL}/indexes/Enums/EducationalLevel/Get`,
    EDIT: `${API_URL}/indexes/Enums/EducationalLevel/Update`
};

export const FILE_TEMPLATE_TYPE = {
    label: 'File Template Type',
    GET: `${API_URL}/indexes/Enums/FileTemplateType/Get`,
    EDIT: `${API_URL}/indexes/Enums/FileTemplateType/Update`
};

export const GENDER = {
    label: 'Gender',
    GET: `${API_URL}/indexes/Enums/Gender/Get`,
    EDIT: `${API_URL}/indexes/Enums/Gender/Update`
};

export const JOB_TITLE_TYPES = {
    label: 'Job Title Types',
    GET: `${API_URL}/indexes/Enums/JobTitleTypes/Get`,
    EDIT: `${API_URL}/indexes/Enums/JobTitleTypes/Update`
};

export const MASTERS = {
    label: 'Masters',
    GET: `${API_URL}/indexes/Enums/Masters/Get`,
    EDIT: `${API_URL}/indexes/Enums/Masters/Update`
};

export const MEMBER_JOB_TITLE_HISTORY_ACTION_TYPE = {
    label: 'Member Job Title History Action Type',
    GET: `${API_URL}/indexes/Enums/MemberJobTitleHistoryActionType/Get`,
    EDIT: `${API_URL}/indexes/Enums/MemberJobTitleActionType/Update`
};
export const MEMBER_TYPES = {
    label: 'Member Types',
    GET: `${API_URL}/indexes/Enums/MemberTypes/Get`,
    EDIT: `${API_URL}/indexes/Enums/MemberTypes/Update`
};

export const MEMBER_KINDS = {
    label: 'Member Kinds',
    GET: `${API_URL}/indexes/Enums/MemberKinds/Get`,
    EDIT: `${API_URL}/indexes/Enums/MemberKinds/Update`
};

export const MESSAGE_TYPE = {
    label: 'Message Type',
    GET: `${API_URL}/indexes/Enums/MessageType/Get`,
    EDIT: `${API_URL}/indexes/Enums/MessageType/Update`
};

export const NUMBERING_PATTERN = {
    label: 'Numbering pattern',
    GET: `${API_URL}/indexes/Enums/NumberingPatterns/Get`,
    EDIT: `${API_URL}/indexes/Enums/NumberingPatterns/Update`
};

export const PLATFORM = {
    label: 'Platform',
    GET: `${API_URL}/indexes/Enums/Platform/Get`,
    EDIT: `${API_URL}/indexes/Enums/Platform/Update`
};

export const PROFESSIONAL_SKILL_LEVEL_TYPE = {
    label: 'Professional Skill Level Type',
    GET: `${API_URL}/indexes/Enums/ProfessionalSkillLevelType/Get`,
    EDIT: `${API_URL}/indexes/Enums/ProfessionalSkillLevelType/Update`
};

export const PROFESSIONAL_EXPERIENCE_LEVEL_TYPE = {
    label: 'Professional Experience Level Type',
    GET: `${API_URL}/indexes/Enums/ProfessionalExperianceLevelType/Get`,
    EDIT: `${API_URL}/indexes/Enums/ProfessionalExperianceLevelType/Update`
};

export const PROFESSIONAL_SKILL_OR_COURSE_TYPE = {
    label: 'Professional Skill or Course Type',
    GET: `${API_URL}/indexes/Enums/ProfessionalSkillOrCourseType/Get`,
    EDIT: `${API_URL}/indexes/Enums/ProfessionalSkillOrCourseType/Update`
};

export const PROGRESS_STATUS = {
    label: 'Progress Status',
    GET: `${API_URL}/indexes/Enums/ProgressStatus/Get`,
    EDIT: `${API_URL}/indexes/Enums/ProgressStatus/Update`
};

export const SECTION_BUSINESS_LOGIC = {
    label: 'Section Business Logic',
    GET: `${API_URL}/indexes/Enums/SectionBusinessLogic/Get`,
    EDIT: `${API_URL}/indexes/Enums/SectionBusinessLogic/Update`
};

export const SECTORS = {
    label: 'Sectors',
    GET: `${API_URL}/indexes/Enums/Sectors/Get`,
    EDIT: `${API_URL}/indexes/Enums/Sectors/Update`
};

export const SUBSCRIPTIONS = {
    label: 'Subscriptions',
    GET: `${API_URL}/indexes/Enums/Subscriptions/Get`,
    EDIT: `${API_URL}/indexes/Enums/Subscriptions/Update`
};

export const TASK_TYPES = {
    label: 'Task Types',
    GET: `${API_URL}/indexes/Enums/TaskTypes/Get`,
    EDIT: `${API_URL}/indexes/Enums/TaskTypes/Update`
};

export const WORK_TYPES = {
    label: 'Work Types',
    GET: `${API_URL}/indexes/Enums/WorkTypes/Get`,
    EDIT: `${API_URL}/indexes/Enums/WorkTypes/Update`
};

export const INPUT_TYPE = {
    label: 'input Type',
    GET: `${API_URL}/indexes/Enums/InputType/Get`,
    EDIT: `${API_URL}/indexes/Enums/InputType/Update`
};

export const PAGE_KEYS = {
    label: 'Pages Keys',
    GET: `${API_URL}/indexes/Enums/SystemLanguagePage/Get`,
    EDIT: `${API_URL}/indexes/Enums/SystemLanguagePage/Update`
};

export const LANGUAGE_LEVELS = {
    label: 'Pages Keys',
    GET: `${API_URL}/indexes/Enums/LanguageLevels/Get`,
    EDIT: `${API_URL}/indexes/Enums/LanguageLevels/Update`
};

// ProfessionalIndexes
export const PROFESSIONAL_INDEXES = {
    LEVELS: {
        GET: `${API_URL}/Setting/ProfessionalIndexes/GetLevels`,
        ADD: `${API_URL}/Setting/ProfessionalIndexes/AddLevel`,
        EDIT: `${API_URL}/Setting/ProfessionalIndexes/UpdateLevel`,
        DELETE: `${API_URL}/Setting/ProfessionalIndexes/DeleteLevel`
    },
    COURSE: {
        GET: `${API_URL}/Setting/ProfessionalIndexes/GetCourses`,
        ADD: `${API_URL}/Setting/ProfessionalIndexes/AddCourse`,
        EDIT: `${API_URL}/Setting/ProfessionalIndexes/UpdateCourse`,
        DELETE: `${API_URL}/Setting/ProfessionalIndexes/DeleteCourse`,
        GET_NEW_COURSES: `${API_URL}/Setting/ProfessionalIndexes/GetAllNewCourses`,
        CONFIRM_COURSE: `${API_URL}/Setting/ProfessionalIndexes/ConfirmCourse`,
        REPLACE_COURSE: `${API_URL}/Setting/ProfessionalIndexes/ReplaceCourse`
    },
    SKILLS: {
        GET: `${API_URL}/Setting/ProfessionalIndexes/GetSkills`,
        ADD: `${API_URL}/Setting/ProfessionalIndexes/AddSkill`,
        EDIT: `${API_URL}/Setting/ProfessionalIndexes/UpdateSkill`,
        DELETE: `${API_URL}/Setting/ProfessionalIndexes/DeleteSkill`,
        GET_NEW_SKILLS: `${API_URL}/Setting/ProfessionalIndexes/GetAllNewSkills`,
        CONFIRM_SKILL: `${API_URL}/Setting/ProfessionalIndexes/ConfirmSkill`,
        REPLACE_SKILL: `${API_URL}/Setting/ProfessionalIndexes/ReplaceSkill`
    },
    INSTITUTIONS: {
        GET: `${API_URL}/Setting/ProfessionalIndexes/GetParties`,
        ADD: `${API_URL}/Setting/ProfessionalIndexes/AddParty`,
        EDIT: `${API_URL}/Setting/ProfessionalIndexes/UpdateParty`,
        DELETE: `${API_URL}/Setting/ProfessionalIndexes/DeleteParty`
    },
    EXPERIENCES: {
        GET: `${API_URL}/Setting/ProfessionalIndexes/GetExperiances`,
        ADD: `${API_URL}/Setting/ProfessionalIndexes/AddExperiancel`,
        EDIT: `${API_URL}/Setting/ProfessionalIndexes/UpdateExperiance`,
        DELETE: `${API_URL}/Setting/ProfessionalIndexes/DeleteExperiance`,
        GET_NEW_EXPERIENCES: `${API_URL}/Setting/ProfessionalIndexes/GetAllNewExperiances`,
        CONFIRM_EXPERIENCE: `${API_URL}/Setting/ProfessionalIndexes/ConfirmExperiance`,
        REPLACE_EXPERIENCE: `${API_URL}/Setting/ProfessionalIndexes/ReplaceExperiance`
    }
};

// Academic Profile
export const ACADEMIC_PROFILE = {
    STUDY: {
        GET: `${API_URL}/Common/Study/Get`,
        ADD: `${API_URL}/Common/Study/Add`,
        EDIT: `${API_URL}/Common/Study/Update`,
        DELETE: `${API_URL}/Common/Study/Delete`
    },
    SCIENTIFIC_FILE: {
        GET: `${API_URL}/Main/Member/GetScientificFile`,
        ADD: `${API_URL}/Main/Member/AddScientificFile`,
        UPDATE: `${API_URL}/Main/Member/UpdateScientificFile`
    }
};

// Privilege

export const PRIVILEGE = {
    GET: `${API_URL}/Security/Privilege/Get`
};

// Role

export const ROLE = {
    GET: `${API_URL}/Security/Role/Get`,
    ADD: `${API_URL}/Security/Role/Add`,
    EDIT: `${API_URL}/Security/Role/ModifyRolePrivileges`,
    DELETE: `${API_URL}/Security/Role/Delete`,
    MODIFY_ACCOUNT_ROLES: `${API_URL}/Security/Role/ModifyAccountRoles`
};

// Pharmacy
export const PHARMACY = {
    COMPANY: {
        GET: `${API_URL}/Company/GetAll`,
        ADD: `${API_URL}/Company/Add`,
        EDIT: `${API_URL}/Company/Update`,
        DELETE: `${API_URL}/Company/Delete`
    },
    INVENTORY: {
        GET: `${API_URL}/Inventory/FilterInventory`,
        ADD: `${API_URL}/Inventory/Add`,
        EDIT: `${API_URL}/Inventory/Update`,
        DELETE: `${API_URL}/Inventory/Delete`
    },
    DRUGCOMPOSITION: {
        GET: `${API_URL}/DrugComposition/GetAll`,
        ADD: `${API_URL}/DrugComposition/Add`,
        EDIT: `${API_URL}/DrugComposition/Update`,
        DELETE: `${API_URL}/DrugComposition/Delete`
    },
    MEDIATION: {
        GET: `${API_URL}/Medicine/FilterMedicine`,
        ADD: `${API_URL}/Medicine/Add`,
        EDIT: `${API_URL}/Medicine/Update`,
        DELETE: `${API_URL}/Medicine/Delete`
    },
    MEDIATION_TYPE: {
        GET: `${API_URL}/MedicineType/GetAll`,
        ADD: `${API_URL}/MedicineType/Add`,
        EDIT: `${API_URL}/MedicineType/Update`,
        DELETE: `${API_URL}/MedicineType/Delete`
    }
};

// CLINICAL_DEPARTMENTS

export const CLINICAL_DEPARTMENTS = {
    GET: `${API_URL}/Setting/ClinicalDepartment/GetAllClinicalDepartment`,
    ADD: `${API_URL}/Setting/ClinicalDepartment/Add`,
    EDIT: `${API_URL}/Setting/ClinicalDepartment/Update`,
    DELETE: `${API_URL}/Setting/ClinicalDepartment/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/Setting/ClinicalDepartment/UpdateActivition`,
    ASSIGN_OPERATION: `${API_URL}/Setting/ClinicalDepartment/AssignOperationDepartmentToClinicalDepartment`,
    UN_ASSIGN_OPERATION: `${API_URL}/Setting/ClinicalDepartment/UnassignOperationDepartmentToClinicalDepartment`,
    ASSIGN_DOCTOR: `${API_URL}/Setting/ClinicalDepartment/AssignDoctorMemberToClinicalDepartment`,
    UN_ASSIGN_DOCTOR: `${API_URL}/Setting/ClinicalDepartment/UnassignDoctorMemberToClinicalDepartment`
};

// OPERATIONS_DEPARTMENTS

export const OPERATIONS_DEPARTMENTS = {
    GET: `${API_URL}/Setting/OperationDepartment/GetAllOperationDepartment`,
    ADD: `${API_URL}/Setting/OperationDepartment/Add`,
    EDIT: `${API_URL}/Setting/OperationDepartment/Update`,
    DELETE: `${API_URL}/Setting/OperationDepartment/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/Setting/OperationDepartment/UpdateActivition`
};

// PATIENT_PRODESSION

export const PATIENT_PRODESSION = {
    GET: `${API_URL}/PatientProfession/Get`,
    ADD: `${API_URL}/PatientProfession/Add`,
    EDIT: `${API_URL}/PatientProfession/Update`,
    DELETE: `${API_URL}/PatientProfession/Delete`,
    GET_NEW_PRODESSIONS: `${API_URL}/PatientProfession/GetAllNewProfessions`,
    CONFIRM_PRODESSION: `${API_URL}/PatientProfession/ConfirmNewProfession`,
    REPLACE_PRODESSION: `${API_URL}/PatientProfession/ReplaceNewProfession`
};

// PATIENT_SCIENTIFIC_LEVEL

export const PATIENT_SCIENTIFIC_LEVEL = {
    GET: `${API_URL}/PatientScientificLevel/Get`,
    ADD: `${API_URL}/PatientScientificLevel/Add`,
    EDIT: `${API_URL}/PatientScientificLevel/Update`,
    DELETE: `${API_URL}/PatientScientificLevel/Delete`,
    GET_NEW_SCIENTIFIC: `${API_URL}/PatientScientificLevel/GetAllNewScientificLevels`,
    CONFIRM_SCIENTIFIC: `${API_URL}/PatientScientificLevel/ConfirmNewScientificLevel`,
    REPLACE_SCIENTIFIC: `${API_URL}/PatientScientificLevel/ReplaceNewScientificLevel`
};

// PATIENT

export const PATIENT = {
    GET: `${API_URL}/Patient/Get`,
    ADD: `${API_URL}/Patient/Add`,
    EDIT: `${API_URL}/Patient/Update`,
    UPDATE_ACTIVATION: `${API_URL}/Patient/UpdateActivition`,
    DELETE: `${API_URL}/Patient/Delete`
};

// NUMBERING_PATTERN_SETTING

export const NUMBERING_PATTERN_SETTING = {
    GET: `${API_URL}/Setting/NumberingPattern/Get`,
    GENERATE: `${API_URL}/Setting/NumberingPattern/Generate`,
    EDIT: `${API_URL}/Setting/NumberingPattern/Update`,
    DELETE: `${API_URL}/Setting/NumberingPattern/DeleteAllNumbers`
};

// RECEPTION_DEPARTMENTS

export const RECEPTION_DEPARTMENTS = {
    GET: `${API_URL}/ReceptionDepartment/GetAllReceptionDepts`,
    ADD: `${API_URL}/ReceptionDepartment/Add`,
    EDIT: `${API_URL}/ReceptionDepartment/Update`,
    DELETE: `${API_URL}/ReceptionDepartment/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/ReceptionDepartment/ChangeActivation`,
    ASSIGN_CLINICAL: `${API_URL}/ReceptionDepartment/AssignClinicalDepartmentsToReceptionDepartment`,
    UN_ASSIGN_CLINICAL: `${API_URL}/ReceptionDepartment/UnAssignClinicalDepartmentsToReceptionDepartment`,
    ASSIGN_MEMBER: `${API_URL}/ReceptionDepartment/AssignMemberToReceptionDepartment`,
    UN_ASSIGN_MEMBER: `${API_URL}/ReceptionDepartment/UnAssignMemberToReceptionDepartment`
};

// RESERVATION_TYPE

export const RESERVATION_TYPE = {
    GET: `${API_URL}/ReservationType/GetAllReservationTypes`,
    ADD: `${API_URL}/ReservationType/Add`,
    EDIT: `${API_URL}/ReservationType/Update`,
    DELETE: `${API_URL}/ReservationType/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/ReservationType/ChangeActivation`
};

// MEDICAL_VISITS

export const MEDICAL_VISITS = {
    GET: `${API_URL}/MedicalVisits/GetAllMedicalVisits`,
    ADD: `${API_URL}/MedicalVisits/Add`,
    EDIT: `${API_URL}/MedicalVisits/Update`,
    DELETE: `${API_URL}/MedicalVisits/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/MedicalVisits/ChangeActivation`
};

// MEDICAL_VISIT_DESCRIPTION

export const MEDICAL_VISIT_DESCRIPTION = {
    GET: `${API_URL}/MedicalVisitDescription/GetAllMedicalVisitDescriptions`,
    ADD: `${API_URL}/MedicalVisitDescription/Add`,
    EDIT: `${API_URL}/MedicalVisitDescription/Update`,
    DELETE: `${API_URL}/MedicalVisitDescription/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/MedicalVisitDescription/ChangeActivation`
};

// MEDICAL_VISIT_TYPE

export const MEDICAL_VISIT_TYPE = {
    GET: `${API_URL}/MedicalVisitType/GetAllMedicalVisitTypes`,
    ADD: `${API_URL}/MedicalVisitType/Add`,
    EDIT: `${API_URL}/MedicalVisitType/Update`,
    DELETE: `${API_URL}/MedicalVisitType/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/MedicalVisitType/ChangeActivation`
};

// APPOINTMENT_TYPE

export const APPOINTMENT_TYPE = {
    GET: `${API_URL}/AppointmentType/GetAllAppointmentTypes`,
    ADD: `${API_URL}/AppointmentType/Add`,
    EDIT: `${API_URL}/AppointmentType/Update`,
    DELETE: `${API_URL}/AppointmentType/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/AppointmentType/ChangeActivation`
};

// MASTER_APPOINTMENT_SETTINGS

export const MASTER_APPOINTMENT_SETTINGS = {
    GET: `${API_URL}/MasterAppointmentSettings/GetAllMasterAppointmentSettings`,
    ADD: `${API_URL}/MasterAppointmentSettings/Add`,
    EDIT: `${API_URL}/MasterAppointmentSettings/Update`,
    DELETE: `${API_URL}/MasterAppointmentSettings/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/MasterAppointmentSettings/ChangeActivation`
};

// DOCTOR_APPOINTMENT_SETTINGS

export const DOCTOR_APPOINTMENT_SETTINGS = {
    GET: `${API_URL}/DoctorAppointmentSettings/GetAllDoctorAppointmentSettings`,
    GET_APPOINTMENTS_FOR_DOCTOR: `${API_URL}/DoctorAppointmentSettings/GetDoctorAppointmentSettings`,
    ADD: `${API_URL}/DoctorAppointmentSettings/Add`,
    EDIT: `${API_URL}/DoctorAppointmentSettings/Update`,
    DELETE: `${API_URL}/DoctorAppointmentSettings/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/DoctorAppointmentSettings/ChangeActivation`
};

// ROOMS

export const ROOMS = {
    GET: `${API_URL}/Room/GetAllRooms`,
    ADD: `${API_URL}/Room/Add`,
    EDIT: `${API_URL}/Room/Update`,
    DELETE: `${API_URL}/Room/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/Room/ChangeActivation`
};

// MEDICAL_REFERRALS

export const MEDICAL_REFERRALS = {
    GET: `${API_URL}/MedicalReferrals/GetAllMedicalReferrals`,
    ADD: `${API_URL}/MedicalReferrals/Add`,
    EDIT: `${API_URL}/MedicalReferrals/Update`,
    DELETE: `${API_URL}/MedicalReferrals/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/MedicalReferrals/ChangeActivation`,
    GET_NEW_REFERRALS: `${API_URL}/MedicalReferrals/GetAllNewMedicalReferrals`,
    CONFIRM_REFERRAL: `${API_URL}/MedicalReferrals/ConfirmMedicalReferral`,
    REPLACE_REFERRAL: `${API_URL}/MedicalReferrals/ReplaceMedicalReferral`
};

// EXTERNAL

export const EXTERNAL = {
    GET: `${API_URL}/Main/External/GetAllExternals`,
    GET_NEW: `${API_URL}/Main/External/GetAllAddedExternals`,
    ADD: `${API_URL}/Main/External/Add`,
    EDIT: `${API_URL}/Main/External/Update`,
    DELETE: `${API_URL}/Main/External/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/Main/External/ChangeActivation`,
    GET_NEW_EXTERNALS: `${API_URL}/Main/External/GetAllNewExternal`,
    CONFIRM_EXTERNAL: `${API_URL}/Main/External/ConfirmExternal`,
    REPLACE_EXTERNAL: `${API_URL}/Main/External/ReplaceExternal`
};

// PATIENT_APPOINTMENTS

export const PATIENT_APPOINTMENTS = {
    GET: `${API_URL}/PatientAppointment/GetAllPatientAppointments`,
    ADD: `${API_URL}/PatientAppointment/Add`,
    EDIT: `${API_URL}/PatientAppointment/Update`,
    DELETE: `${API_URL}/PatientAppointment/Delete`,
    CHANGE_STATUS: `${API_URL}/PatientAppointment/ChangeStatus`
};

// ESCORTS_TYPE

export const ESCORTS_TYPE = {
    GET: `${API_URL}/EscortsType/GetAllEscortsTypes`,
    ADD: `${API_URL}/EscortsType/Add`,
    EDIT: `${API_URL}/EscortsType/Update`,
    DELETE: `${API_URL}/EscortsType/Delete`,
    UPDATE_ACTIVATION: `${API_URL}/EscortsType/ChangeActivation`
};

// RELATIVE

export const RELATIVE = {
    GET: `${API_URL}/Relative/GetAllRelatives`,
    ADD: `${API_URL}/Relative/Add`,
    EDIT: `${API_URL}/Relative/Update`,
    DELETE: `${API_URL}/Relative/Delete`
};
