export const RelativesPageConstant = {
    relatives: 'Reception_Management_Relative.relatives',
    relative: 'Reception_Management_Relative.relative',
    name: 'Reception_Management_Relative.name',
    firstName: 'Reception_Management_Relative.firstname',
    lastName: 'Reception_Management_Relative.lastname',
    firstAddress: 'Reception_Management_Relative.firstaddress',
    secondAddress: 'Reception_Management_Relative.secondaddress',
    phoneNumber: 'Reception_Management_Relative.phonenumber',
    whatsappNumber: 'Reception_Management_Relative.whatsappnumber',
    isVoucher: 'Reception_Management_Relative.isvoucher',
    email: 'Reception_Management_Relative.email',
    icon: 'Reception_Management_Relative.icon',
    image: 'Reception_Management_Relative.image',
    actions: 'Reception_Management_Relative.actions',
    code: 'Reception_Management_Relative.code',
    info: 'Reception_Management_Relative.info',
    search: 'Reception_Management_Relative.search',
    reset: 'Reception_Management_Relative.reset',
    uploadIcon: 'Reception_Management_Relative.uploadicon',
    selectLanguage: 'Reception_Management_Relative.selectlanguage',
    createdDate: 'Reception_Management_Relative.createddate',
    updatedDate: 'Reception_Management_Relative.updateddate'
};
