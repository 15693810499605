export const MedicalCompaniesPageConstant = {
    medicalCompanies: 'Medical_Management_Pharmacy_Companies.medicalcompanies',
    medicalCompany: 'Medical_Management_Pharmacy_Companies.medicalcompany',
    name: 'Medical_Management_Pharmacy_Companies.name',
    icon: 'Medical_Management_Pharmacy_Companies.icon',
    image: 'Medical_Management_Pharmacy_Companies.image',
    cities: 'Medical_Management_Pharmacy_Companies.cities',
    countries: 'Medical_Management_Pharmacy_Companies.countries',
    country: 'Medical_Management_Pharmacy_Companies.country',
    city: 'Medical_Management_Pharmacy_Companies.city',
    internationalContactInfos: 'Medical_Management_Pharmacy_Companies.internationalcontactinfos',
    nationalContactInfos: 'Medical_Management_Pharmacy_Companies.nationalcontactinfos',
    phoneNumber: 'Medical_Management_Pharmacy_Companies.phonenumber',
    description: 'Medical_Management_Pharmacy_Companies.description',
    edit: 'Medical_Management_Pharmacy_Companies.edit',
    actions: 'Medical_Management_Pharmacy_Companies.actions',
    delete: 'Medical_Management_Pharmacy_Companies.delete',
    createdDate: 'Medical_Management_Pharmacy_Companies.createddate',
    updatedDate: 'Medical_Management_Pharmacy_Companies.updateddate'
};
