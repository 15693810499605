import PropTypes from 'prop-types';
import React from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
// import { createTheme, ThemeProvider } from '@mui/system';
import { StyleSheetManager } from 'styled-components';
import themes from 'themes';
import { ThemeProvider } from '@mui/material/styles';

// hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDataContext } from 'context';
import { useEffect } from 'react';
import { useState } from 'react';
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin]
});

function RTL({ children }) {
    const { languages, defaultLanguagesList } = useDataContext();
    const { t, i18n } = useTranslation('admin');
    const [isRtl, setIsRtl] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const theme = useSelector((state) => state.theme);
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        if (token && languages && languages?.length > 0) {
            if (i18n.language) {
                const selectedLanguageDirection = languages?.find((language) => language.key == i18n.language);
                setSelectedLanguage(selectedLanguageDirection?.direction);

                setIsRtl(selectedLanguageDirection?.direction == 'rtl' ?? flase);
            }
        } else {
            if (localStorage.getItem('activeLanguage') && defaultLanguagesList && defaultLanguagesList?.length > 0) {
                const selectedLanguageDirection = defaultLanguagesList?.find(
                    (language) => language.key == localStorage.getItem('activeLanguage')
                );

                setSelectedLanguage(selectedLanguageDirection?.direction);
                setIsRtl(selectedLanguageDirection?.direction == 'rtl' ?? flase);
            }
        }
    }, [localStorage.getItem('activeLanguage'), i18n.language, languages, defaultLanguagesList]);

    React.useLayoutEffect(() => {
        document.body.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
    }, [isRtl]);
    return (
        <ThemeProvider theme={themes(theme, isRtl ? 'rtl' : 'ltr')}>
            {isRtl ? (
                <StyleSheetManager stylisPlugins={[rtlPlugin]}>
                    <CacheProvider value={cacheRtl}>
                        <div dir="rtl">{children}</div>
                    </CacheProvider>
                </StyleSheetManager>
            ) : (
                children
            )}
        </ThemeProvider>
    );
}

RTL.propTypes = {
    children: PropTypes.any.isRequired
};

export default RTL;
